import { Box, styled } from '@mui/material';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { booleanish } from 'types/booleanish.types';
import { DatasetStatus } from 'types/dataset.types';
import DatasetValidationForm from '../../Forms/DatasetValidationForm';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const ValidateGeoData: FC = () => {
  const { statement } = useEudrComplianceGuide();
  const { error, loading } = { error: false, loading: false };

  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();

  useEffect(() => {
    if (statement && statement.datasets.length) {
      setSelectedDatasetId(statement.datasets[0].id);
    }
  }, [statement]);

  const selectedDatasetTableRepresentation = useMemo(() => {
    const selectedDataset = statement?.datasets.find(({ id }) => id === selectedDatasetId);
    if (selectedDataset && selectedDataset.tableRepresentation?.length) {
      // TODO: The type of the tableRepresentation is still an array from the backend but it should be just one table representation
      return selectedDataset.tableRepresentation[0];
    }
  }, [statement, selectedDatasetId]);

  if (loading) {
    return <Loader />;
  }
  if (error || !statement || !selectedDatasetTableRepresentation) {
    return <ErrorState />;
  }

  return (
    <>
      <EudrComplianceGuideSubSection allowNextStepNavigation>
        <Box display="flex" gap={2} flexWrap="wrap">
          {statement?.datasets.map(
            ({ status, id, title, rawMaterial, originCountry, ownedBy: partner }) => (
              <DatasetContainer key={id} onClick={() => setSelectedDatasetId(id)}>
                <DatasetItem
                  title={title}
                  commodity={rawMaterial.title}
                  location={originCountry}
                  owner={partner.name}
                  active={id === selectedDatasetId}
                  status={
                    status === DatasetStatus.VALIDATED || status === DatasetStatus.NORMALISED
                      ? 'valid'
                      : 'error'
                  }
                />
              </DatasetContainer>
            )
          )}
        </Box>
      </EudrComplianceGuideSubSection>

      <Box mt={2}>
        <DatasetValidationForm
          mode="validation"
          tableRepresentation={selectedDatasetTableRepresentation}
        />
      </Box>
    </>
  );
};

export default ValidateGeoData;
