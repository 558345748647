import { Button, ButtonProps, CircularProgress, Tooltip } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import ThemeTypography from '../../Primitives/Typography/ThemeTypography';
import { ThemeTypographyColor } from '../../utils/designSystemUtils';
import React, { useEffect } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';

const getButtonHeight = (size: Props['size']) => {
  switch (size) {
    case 'large':
      return 36;
    case 'medium':
      return 30;
    case 'small':
      return 22;
    default:
      break;
  }
};

const getButtonPadding = (size: Props['size'], theme: Theme) => {
  switch (size) {
    case 'large':
      return theme.spacing(0, 4);
    case 'medium':
      return theme.spacing(0, 4);
    case 'small':
      return theme.spacing(0, 1);
    default:
      break;
  }
};

const getButtonColors = (
  color: ButtonColor,
  theme: Theme
): {
  background: string;
  color: string;
  hover: {
    background: string;
    color?: string;
  };
} => {
  switch (color) {
    case 'YELLOW':
      return {
        background: theme.palette.primary.main,
        color: 'inherit',
        hover: {
          background: '#FFCC47',
        },
      };
    case 'YELLOW_40':
      return {
        background: theme.custom.themeColors.accent[40],
        color: 'inherit',
        hover: {
          background: theme.custom.themeColors.accent[60],
        },
      };
    case 'BLUE':
      return {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        hover: {
          background: theme.palette.secondary.light,
          color: theme.custom.colors.secondaryLighter,
        },
      };
    case 'BLUE_ICE':
      return {
        background: theme.custom.colors.backgroundLight,
        color: 'inherit',
        hover: {
          background: theme.custom.colors.backgroundLight,
          color: theme.custom.colors.secondaryLighter,
        },
      };
    case 'WHITE':
      return {
        background: theme.palette.common.white,
        color: theme.palette.secondary.main,
        hover: {
          background: theme.custom.themeColors.grayScale[20],
        },
      };
    default:
      return {
        background: theme.palette.primary.main,
        color: 'inherit',
        hover: {
          background: theme.palette.primary.light,
        },
      };
  }
};

const StyledButton = styled(Button)<{
  size: Props['size'];
  'button-color': ButtonColor;
  padding?: string | number;
  'auto-overflow'?: booleanish;
}>(({ theme, size, 'button-color': buttonColor, padding, 'auto-overflow': autoOverflow }) => ({
  overflow: 'hidden',
  padding: padding ? padding : getButtonPadding(size, theme),
  height: getButtonHeight(size),
  backgroundColor: getButtonColors(buttonColor, theme).background,
  color: getButtonColors(buttonColor, theme).color,

  '&:hover': {
    ...getButtonColors(buttonColor, theme).hover,

    // Button text is wrapped in a p, so we need to target it
    '& .MuiTypography-root': {
      color: `${getButtonColors(buttonColor, theme).hover.color} !important`,
    },
  },
  ...(autoOverflow === 'true'
    ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
    : {}),
}));

const DisabledContainer = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
}));

const ProgressContainer = styled('div')<{ visible: boolean }>(({ theme, visible }) => ({
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: '0',
  right: '0',
  opacity: 0,
  transform: 'translateY(30px)',
  transition: 'transform 0.125s ease-in',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > *': {
    color: theme.palette.secondary.main,
  },

  ...(visible && {
    opacity: 1,
    transform: 'translateY(0)',
  }),
}));

interface LabelProps {
  visible: boolean;
}

const Label = styled('span')<LabelProps>(({ visible }) => {
  return {
    transition: 'all 0.125s ease-in',
    opacity: 1,
    transform: 'translateY(0)',
    lineHeight: 1,

    ...(!visible && {
      opacity: 0,
      transform: 'translateY(-30px)',
    }),
  };
});

export type ButtonColor = 'YELLOW' | 'BLUE' | 'BLUE_ICE' | 'WHITE' | 'YELLOW_40';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  color?: ButtonColor;
  typographyColor?: ThemeTypographyColor;
  tooltip?: string;
  padding?: string | number;
  target?: '_blank' | '_self';
  href?: string;
  autoOverflow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent) => void;
}

const ThemeButton: React.FC<Props & Omit<ButtonProps, 'color'>> = ({
  children,
  loading = false,
  disabled,
  onClick,
  type,
  variant = 'contained',
  size = 'medium',
  color = 'YELLOW',
  typographyColor = 'BLACK',
  tooltip = '',
  padding,
  autoOverflow,
  startIcon,
  ...rest
}) => {
  const triggerSubmit = (event: KeyboardEvent) => {
    if (type === 'submit' && event.key === 'Enter' && typeof onClick === 'function') {
      onClick(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', triggerSubmit);

    return () => window.removeEventListener('keydown', triggerSubmit);
  }, []); // eslint-disable-line

  return (
    <Tooltip title={disabled || loading ? tooltip : ''}>
      <DisabledContainer>
        <StyledButton
          type={type}
          onClick={onClick}
          disabled={loading || disabled}
          variant={variant}
          size={size}
          button-color={color}
          padding={padding}
          auto-overflow={Booleanish(autoOverflow)}
          startIcon={!loading ? startIcon : undefined}
          {...rest}
        >
          <ProgressContainer visible={loading}>
            <CircularProgress size={16} thickness={5} />
          </ProgressContainer>
          <Label visible={!loading}>
            <ThemeTypography
              variant={size === 'large' ? 'BUTTON_LARGE' : 'BUTTON_SMALL'}
              color={disabled ? 'GRAY_40' : typographyColor}
              autoOverflow
            >
              {children}
            </ThemeTypography>
          </Label>
        </StyledButton>
      </DisabledContainer>
    </Tooltip>
  );
};

export default ThemeButton;
