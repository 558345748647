import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COUNTRIES } from 'constants/countries';
import isEqual from 'lodash/isEqual';
import React, { ChangeEvent, FC, memo, useMemo } from 'react';

interface ICountryAutocompleteProps {
  value?: string | null; // Country code
  placeholder?: string;
  size?: 'small' | 'medium';
  hasError?: boolean;
  errorMessage?: string;
  onCountrySelect: (countryCode: string | null) => void;
  disabled?: boolean;
}

const StyledAutocomplete = styled(
  memo(Autocomplete<{ code: string; label: string }>, (prev, next) => {
    return isEqual(prev, next) && isEqual(prev.value, next.value);
  })
)(() => ({
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: 4,
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
    {
      padding: 4,
    },
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  '&.MuiFormHelperText-root': {
    marginTop: -2,
  },
}));

const CountryAutocomplete: FC<ICountryAutocompleteProps> = ({
  value = null,
  placeholder = 'Select country',
  hasError,
  errorMessage = 'Please select a country',
  size = 'medium',
  disabled,
  onCountrySelect,
}) => {
  const selectedCountry = useMemo(
    () => COUNTRIES.find(country => country.code === value?.toLocaleUpperCase()),
    [value]
  );

  const handleSelectCountry = (event: ChangeEvent<unknown>, country: { code: string } | null) => {
    onCountrySelect(country?.code || null);
  };

  return (
    <FormControl fullWidth size={size} error={hasError}>
      <StyledAutocomplete
        value={selectedCountry || null}
        options={COUNTRIES}
        disabled={disabled}
        data-cy="country-autocomplete"
        fullWidth
        size={size}
        renderOption={(props, option) => (
          <li {...props} key={option.code} data-cy="autocomplete-option">
            {option?.label}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            placeholder={placeholder}
            variant="outlined"
            onKeyPress={keyEvent => {
              // Prevents the form from being submitted on enter.
              if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
              }
            }}
          />
        )}
        onChange={handleSelectCountry}
      />
      {hasError && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}
    </FormControl>
  );
};

export default CountryAutocomplete;
