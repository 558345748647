import { ApolloError, useMutation } from '@apollo/client';
import { Box, DialogActions, DialogContent, Grid, styled } from '@mui/material';
import { MediaField } from 'components/Forms';
import { useDialog, useLogEvent, useMessages } from 'components/hooks';
import { PartnerInviteSchema } from 'constants/schemas';
import { IPartnerFormValues, partnerSchema } from 'constants/schemas/partners.schema';
import { DialogDefault, FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import {
  CREATE_PARTNER_COMPANY,
  INVITE_PARTNER_COMPANY,
  UPDATE_PARTNER_COMPANY,
} from 'graphql/mutations/partner.mutations';
import { GET_BASE_PARTNER_COMPANY } from 'graphql/queries/partner.queries';
import isEqual from 'lodash/isEqual';
import React, { FC, useRef } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { addNewPartnerToCache } from '../utils/partnerUtils';

export interface IAddEditPartnerDialogDialog extends IDefaultDialogProps {
  enableInvite?: boolean;
  /**
   * This will save the invitation email and callback can be used to get the created partner
   */
  skipInvitationEmail?: boolean;
  /**
   * @deprecated for sub partners will be enabled in the future again z
   */
  connectToCompanyId?: string;
  /**
   */
  partner?: IBasePartnerCompany;
  onPartnerCreated?: (partner: IBasePartnerCompany) => void;
}

const TopBackground = styled('div')(({ theme }) => ({
  '&::before': {
    content: '""',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    height: '42%',
    background: theme.custom.colors.backgroundLight,
  },
}));

const StyledCard = styled(Grid)(({ theme }) => ({
  borderRadius: 6,
  background: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  minHeight: 150,
  padding: theme.spacing(2, 2, 4, 2),
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 3, 3, 5),
  overflow: 'visible',
  zIndex: 2,
}));

const AddEditPartnerDialog: FC<IAddEditPartnerDialogDialog> = ({
  enableInvite,
  partner,
  skipInvitationEmail,
  connectToCompanyId,
  onClose,
  onPartnerCreated,
  open = true,
}) => {
  const formRef = useRef<FormikProps<IPartnerFormValues>>(null);
  const { openDialog } = useDialog();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { logEvent } = useLogEvent();

  const isEdit = !!partner;

  const initialValues: IPartnerFormValues = {
    ...PartnerInviteSchema.default(),
    ...(partner
      ? {
          id: partner.id,
          title: partner.name,
          image: partner.logo || undefined,
          externalId: partner.externalId,
        }
      : {}),
  };

  const [createPartner] = useMutation<
    { createPartner: { partner: IBasePartnerCompany } },
    { input: Partial<IBasePartnerCompany> }
  >(CREATE_PARTNER_COMPANY, {
    onError: () => setErrorMessage('There was an error creating your partner'),
    update: (cache, createdPartner) => {
      const newPartner = createdPartner.data?.createPartner.partner;
      if (newPartner) {
        addNewPartnerToCache(cache, newPartner);
      }
    },
  });

  const [updatePartner] = useMutation<
    { updatePartner: { partner: IBasePartnerCompany } },
    { id: string; input: Partial<IBasePartnerCompany> }
  >(UPDATE_PARTNER_COMPANY, {
    onError: () => setErrorMessage('There was an error updating your partner'),
  });

  const [invitePartner] = useMutation<
    { invitePartner: { email: string; partnerId: string } },
    { email: string; partnerCompanyId: string; sendEmail?: boolean }
  >(INVITE_PARTNER_COMPANY, {
    onCompleted: () => {
      setSuccessMessage(
        'Nice! Your partner has been invited! You will receive a confirmation email when your partner has accepted the invitation.'
      );
      logEvent('PARTNER_INVITED');
      onClose?.();
    },
    onError: (error: ApolloError) => {
      if (error.message === 'USER_EXISTS') {
        setErrorMessage('This user email address already exists in the system');
      } else {
        setErrorMessage(error.message);
      }
    },
  });

  const handleInviteExistingPartner = async (email: string, partnerCompanyId: string) => {
    await invitePartner({
      variables: {
        email,
        partnerCompanyId,
        sendEmail: !skipInvitationEmail,
      },
      refetchQueries: [
        {
          query: GET_BASE_PARTNER_COMPANY,
          variables: {
            id: partnerCompanyId,
          },
        },
      ],
    });
  };

  const handleSubmit = async (
    values: IPartnerFormValues,
    { setSubmitting }: FormikHelpers<IPartnerFormValues>
  ) => {
    let partnerIdToInvite: string | undefined;

    // Does the partner values actually changed
    if (!isEqual(initialValues, formRef.current?.values)) {
      if (values.id && partner) {
        // Does the partner needs to be edited
        await updatePartner({
          variables: {
            id: values.id,
            input: { name: values.title, logo: values.image, externalId: values.externalId },
          },
        });
        partnerIdToInvite = values.id;
      } else {
        // Does the partner needs to be created
        const newPartner = await createPartner({
          variables: {
            input: {
              name: values.title,
              logo: values.image,
              externalId: values.externalId,
            },
          },
        });

        if (newPartner.data?.createPartner.partner) {
          onPartnerCreated?.(newPartner.data?.createPartner.partner);
        }

        partnerIdToInvite = newPartner.data?.createPartner.partner.id;
      }
    }

    // Check if we need to invite the partner
    if (enableInvite && values.email && partnerIdToInvite) {
      await handleInviteExistingPartner(values.email, partnerIdToInvite);
    }

    setSubmitting(false);
    onClose?.();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialValues)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          itemTitle: formRef.current?.values?.title,
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  return (
    <DialogDefault
      title={enableInvite ? 'Invite partner' : isEdit ? 'Edit partner' : 'New partner'}
      open={open}
      fullWidth
      maxWidth="md"
      iconName={enableInvite ? 'send' : isEdit ? 'edit' : 'plus'}
      data-cy="partner-form-dialog"
      onClose={handleClose}
    >
      <TopBackground />

      <Box zIndex={2}>
        <Formik<IPartnerFormValues>
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={enableInvite ? PartnerInviteSchema : partnerSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, values }) => {
            return (
              <Form>
                <StyledDialogContent>
                  <StyledCard container spacing={2}>
                    <Grid item xs={3}>
                      <FieldWrapper fullWidth>
                        <Field
                          component={MediaField}
                          name="image"
                          legacyImage={false}
                          styles={{
                            height: 130,
                          }}
                        />
                      </FieldWrapper>
                    </Grid>

                    <Grid item xs={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FieldWrapper label="Partner Name" variant="small" required>
                            <Field
                              size="small"
                              component={TextField}
                              fullWidth
                              placeholder="Company"
                              name="title"
                              variant="outlined"
                              data-testid="partner-title-input"
                            />
                          </FieldWrapper>
                        </Grid>

                        <Grid item xs={12}>
                          <FieldWrapper
                            label="Internal ID"
                            variant="small"
                            tooltip={{
                              helperText:
                                "This should be an unique identifier used to track and manage partner-related data for example within your company's ERP system.",
                              variant: 'INFO',
                            }}
                          >
                            <Field
                              component={TextField}
                              size="small"
                              placeholder="Unique identifier (optional)"
                              fullWidth
                              name="externalId"
                              variant="outlined"
                              data-testid="external-id-input"
                            />
                          </FieldWrapper>
                        </Grid>

                        {enableInvite && (
                          <Grid item xs={12}>
                            <FieldWrapper label="Partner Email" variant="small" required mb={2}>
                              <Field
                                component={TextField}
                                fullWidth
                                size="small"
                                placeholder="contact@partner.com"
                                name="email"
                                variant="outlined"
                              />
                            </FieldWrapper>
                            <ThemeTypography variant="BODY_SMALL" color="GRAY">
                              The partner will receive an email where they can login to the
                              seedtrace platform. There, they can then edit their own information
                              and share data that you request from them.
                            </ThemeTypography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </StyledCard>
                </StyledDialogContent>

                <StyledDialogActions>
                  <ThemeButton color="BLUE_ICE" fullWidth size="large" onClick={handleClose}>
                    Cancel
                  </ThemeButton>
                  <ThemeButton
                    color="YELLOW"
                    data-testid="submit-partner-form-btn"
                    fullWidth
                    disabled={!isValid || isEqual(values, initialValues)}
                    startIcon={
                      !enableInvite || skipInvitationEmail ? undefined : <Icon name="send" />
                    }
                    size="large"
                    type="submit"
                    loading={isSubmitting}
                  >
                    {!enableInvite || skipInvitationEmail ? 'Save' : 'Send Invite'}
                  </ThemeButton>
                </StyledDialogActions>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </DialogDefault>
  );
};

export default AddEditPartnerDialog;
