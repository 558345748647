import { Box, DialogActions, DialogContent } from '@mui/material';
import {
  CSV_SEPARATOR_OPTIONS,
  DECIMAL_SEPARATOR_OPTIONS,
} from 'components/ComplianceGuide/utils/eudrCompliance.utils';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { Loader } from 'components/Forms';
import { useDialog, useMessages, useUploadState } from 'components/hooks';
import {
  geoDataCustomValidation,
  geoMappingDatasetSchema,
  geoMappingSchema,
  geoUploadDatasetSchema,
  geoUploadSchema,
  IGeoMappingFormValues,
  IGeoUploadFormValue,
  IGeoUploadFormValues,
  mappedColumnsSchema,
} from 'constants/schemas/geoUpload.schema';
import { DialogDefault, ThemeButton } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import { Form, Formik, FormikProps } from 'formik';
import { IProcessDatasetInput } from 'graphql/mutations/types/dataset-mutation.types';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatasetErrorEntity, DatasetMapping, DatasetStatus, IDataset } from 'types/dataset.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { IBasePartnerCompany } from 'types/partner.types';
import DatasetMappingForm from '../Forms/DatasetMappingForm';
import DatasetUploadForm from '../Forms/DatasetUploadForm';

interface IDatasetImportDialogProps extends IDefaultDialogProps {
  /**
   * The partner company that the dataset will be owned by
   * Passing this will disable the owner selection in the upload form
   */
  ownedBy?: IBasePartnerCompany;
}

const DatasetImportDialog: FC<IDatasetImportDialogProps> = ({ open, ownedBy, onClose }) => {
  const [dataset, setDataset] = useState<IDataset | undefined>(undefined);
  const [datasetMapping, setDatasetMapping] = useState<DatasetMapping | undefined>(undefined);

  const { createDatasets, updateDatasets, startDatasetProcessing, deleteDataset } =
    useDatasetMutation();

  const uploadFormRef = useRef<FormikProps<IGeoUploadFormValues>>(null);
  const mappingFormRef = useRef<FormikProps<IGeoMappingFormValues>>(null);
  const { openDialog } = useDialog();
  const { undoFileUploads, handleCleanCreatedRecords } = useUploadState();
  const navigate = useNavigate();
  const location = useLocation();
  const { setErrorMessage } = useMessages();

  const uploadInitialFormValues: IGeoUploadFormValues = [
    {
      ...geoUploadDatasetSchema.default(),
      ...(ownedBy ? { ownedBy } : {}),
    },
  ];

  useEffect(() => {
    if (mappingFormRef.current && datasetMapping && dataset) {
      const initialValues: IGeoMappingFormValues = [
        {
          ...geoMappingDatasetSchema.default(),
          datasetId: dataset.id,
          mappedColumns: datasetMapping.columnsData.map(column => {
            return {
              ...mappedColumnsSchema.default(),
              sourceColumnName: column.columnName,
              columnDataExample: column.columnDataExample,
            };
          }),
        },
      ];
      mappingFormRef.current.setValues(initialValues, false);
    }
  }, [datasetMapping, dataset, mappingFormRef]);

  const handleSubmitUpload = async (values: IGeoUploadFormValues) => {
    try {
      if (values.length === 0) {
        throw new Error('The form values are not defined');
      }

      const datasetIds: string[] = [];

      // Create the datasets that do not exists yet
      const createDatasetValues = values.filter(({ datasetId }) => !datasetId); // The datasets were not created yet
      if (createDatasetValues.length) {
        const datasetCreationResults = await createDatasets(createDatasetValues);

        datasetIds.push(
          ...(datasetCreationResults
            .map(result => result.data?.createDataset.dataset.id)
            .filter(Boolean) as string[])
        );

        // Update form with the created dataset ids
        uploadFormRef.current?.setValues(
          values.map((value, index) => ({
            ...value,
            datasetId: datasetIds[index],
          })),
          false
        );
      }

      // Update the datasets that already exists
      const updateDatasetValues = values.filter(
        ({ datasetId }) => !!datasetId
      ) as (IGeoUploadFormValue & {
        datasetId: string;
      })[]; // The value were datasetId are set were already created
      if (updateDatasetValues.length) {
        const updateDatasetsResults = await updateDatasets(updateDatasetValues);

        datasetIds.push(
          ...(updateDatasetsResults
            .map(result => result.data?.updateDataset.dataset.id)
            .filter(Boolean) as string[])
        );
      }

      if (datasetIds.length === 0) {
        throw new Error('An issue occurred while creating the datasets');
      }

      const inputs: IProcessDatasetInput[] = datasetIds.map(id => {
        const fileConfig = values[0]?.fileConfiguration;
        return {
          datasetId: id,
          decimalSeperator:
            (fileConfig?.decimalSeparator &&
              DECIMAL_SEPARATOR_OPTIONS[fileConfig.decimalSeparator].value) ||
            undefined,
          csvSeperator:
            (fileConfig?.csvSeparator && CSV_SEPARATOR_OPTIONS[fileConfig.csvSeparator].value) ||
            undefined,
        };
      });
      const response = await startDatasetProcessing(inputs);

      if (!response.data?.processDatasets?.datasets?.length) {
        throw new Error('No datasets were created');
      }

      const datasetErrors = response.data?.processDatasets.datasetProcessingErrors;

      if (response.errors?.length) {
        throw new Error('Unidentified errors occurred in the backend');
        // If some files could not be processed, we can not continue
      } else if (
        response.data?.processDatasets.datasets.some(
          ({ status }) => status === DatasetStatus.PARSING_FAILED
        )
      ) {
        // If there are any parsing errors in the dataset we try to show them on the correct file in the upload form
        if (
          datasetErrors?.length &&
          datasetErrors.some(({ datasetErrors }) =>
            datasetErrors.some(({ entityType }) => entityType === DatasetErrorEntity.DOCUMENT)
          )
        ) {
          // We need to place the error at the correct array index of the form
          // 1. Find the index of the datasetId in the form values matching the processingError
          // 2. Find the index of the file in the files array matching the processingError
          const parsingErrors = uploadFormRef.current?.values.map(({ datasetId, files }) => {
            const errors = datasetErrors.find(
              ({ datasetId: errorDatasetId }) => errorDatasetId === datasetId
            )?.datasetErrors;
            if (errors?.some(({ entityType }) => entityType === DatasetErrorEntity.DOCUMENT)) {
              return {
                datasetId,
                files: files.map(({ id }) => {
                  const documentError = errors.find(
                    ({ entityType, entityId }) =>
                      entityType === DatasetErrorEntity.DOCUMENT && entityId === id
                  );
                  return documentError ? documentError.errorMessage : undefined;
                }),
              };
            }
            return undefined;
          });
          // @ts-ignore
          uploadFormRef.current?.setErrors(parsingErrors);
        } else {
          throw new Error(
            'The dataset errors occurred while parsing the dataset could not be matched to the files in the form'
          );
        }

        // If there are mapping errors we show the mapping form
      } else if (
        response.data?.processDatasets.datasets.some(
          ({ status }) => status === DatasetStatus.MAPPING_FAILED
        ) &&
        response.data?.processDatasets.datasetMappings?.length
      ) {
        setDataset(response.data.processDatasets?.datasets[0]);
        setDatasetMapping(response.data.processDatasets.datasetMappings[0]);

        // If there are not errors the dataset got created and we show the it in the dataset overview
      } else {
        const datasetId = response.data?.processDatasets.datasets[0].id;
        navigate(`${location.pathname}/dataset/${datasetId}`);
        onClose?.();
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'An error occurred while processing the dataset. If the error persists, please contact support.'
      );
    } finally {
      uploadFormRef.current?.setSubmitting(false);
    }
  };

  const handleSubmitMapping = async (values: IGeoMappingFormValues) => {
    try {
      const response = await startDatasetProcessing(
        values.map(({ datasetId, mappedColumns }) => ({
          datasetId,
          // Remove the excluded columns and the columnDataExample
          mappedColumns:
            mappedColumns
              .filter(({ removed }) => !removed)
              .map(mapped => omit(mapped, ['columnDataExample', 'removed'])) || [],
        }))
      );

      // Unknown errors
      if (!response.data?.processDatasets?.datasets?.length) {
        throw new Error('No datasets were created');
      } else if (
        response.data?.processDatasets.datasets.some(
          ({ status }) => status === DatasetStatus.MAPPING_FAILED
        )
      ) {
        // Throw error from backend to the user
        if (
          response.data?.processDatasets.datasetProcessingErrors?.length &&
          response.data?.processDatasets.datasetProcessingErrors[0].datasetErrors.length
        ) {
          setErrorMessage(
            response.data.processDatasets.datasetProcessingErrors[0].datasetErrors[0].errorMessage
          );
          return;
          // Dataset mapping failed
        } else {
          throw new Error('Dataset mapping failed.');
        }
      }
      const datasetId = response.data?.processDatasets.datasets[0].id;
      navigate(`${location.pathname}/dataset/${datasetId}`);
      onClose?.();
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'An error occurred while processing the dataset. If the error persists, please contact support.'
      );
    } finally {
      mappingFormRef.current?.setSubmitting(false);
    }
  };

  const undoChangesAndClose = () => {
    onClose?.();
    // If dataset got created delete it (can be in the mapping or upload state with processing errors)
    const datasetIdToDelete = uploadFormRef.current?.values?.[0]?.datasetId || dataset?.id;
    if (datasetIdToDelete) {
      deleteDataset({ id: datasetIdToDelete });
    }
    undoFileUploads();
    handleCleanCreatedRecords();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (
      (uploadFormRef.current &&
        !isEqual(uploadFormRef.current.values, uploadFormRef.current.initialValues)) ||
      (mappingFormRef.current && dataset)
    ) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          displayCloseButton: true,
          onSubmit: () => {
            undoChangesAndClose();
          },
          onCancel: () => undefined,
        },
      });
    } else {
      undoChangesAndClose();
    }
  };

  return (
    <DialogDefault
      open={!!open}
      title="Upload new dataset"
      iconName="upload"
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
    >
      <>
        {/* Upload & dataset creation */}
        {!dataset && (
          <Formik<IGeoUploadFormValues>
            innerRef={uploadFormRef}
            initialValues={uploadInitialFormValues}
            validateOnChange
            validationSchema={geoUploadSchema}
            onSubmit={handleSubmitUpload}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <DialogContent>
                  <Box maxHeight="80vh" overflow="auto">
                    <DatasetUploadForm datasetIndex={0} disableOwnerSelection={!!ownedBy} />
                  </Box>
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'space-between' }}>
                  <ThemeButton color="BLUE_ICE" onClick={handleClose}>
                    Cancel
                  </ThemeButton>
                  <ThemeButton
                    color="YELLOW"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Continue
                  </ThemeButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}

        {/* Dataset column mapping */}
        {dataset?.status === DatasetStatus.MAPPING_FAILED && (
          <Formik<IGeoMappingFormValues>
            innerRef={mappingFormRef}
            initialValues={geoMappingSchema.default()}
            validationSchema={geoMappingSchema}
            validate={geoDataCustomValidation}
            onSubmit={handleSubmitMapping}
          >
            {({ isSubmitting, isValid, values, errors }) => (
              <Form>
                <DialogContent>
                  <DatasetItem
                    active
                    title={dataset.title}
                    commodity={dataset.rawMaterial.title}
                    location={dataset.originCountry}
                    owner={dataset.ownedBy.name}
                    status={errors[0] ? 'error' : undefined}
                  />

                  {!mappedColumnsSchema || !values.length ? (
                    <Loader />
                  ) : (
                    <Box maxHeight="70vh" overflow="auto">
                      <DatasetMappingForm datasetIndex={0} />
                    </Box>
                  )}
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'space-between' }}>
                  <ThemeButton color="BLUE_ICE" onClick={handleClose}>
                    Cancel
                  </ThemeButton>
                  <ThemeButton
                    color="YELLOW"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Save dataset
                  </ThemeButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </>
    </DialogDefault>
  );
};

export default DatasetImportDialog;
