import React, { FC } from 'react';
import { Box, IconButton, styled, Tooltip } from '@mui/material';
import { CardContainer, ThemeTypography } from 'designSystem';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { SiteLabelKeys } from 'utils/site.utils';
import { ISite } from 'types/site.types';
import { DatasetError } from 'types/dataset.types';

interface ISiteItemProps {
  site: ISite;
  warnings: DatasetError[];
  onEdit: (site: ISite) => void;
  onEditCultivationArea: (site: ISite) => void;
  onDelete: (site: ISite) => void;
}

const DatasetItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const NumberCyrcle = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.custom.themeColors.accent[60],
}));

const WarningTextContainer = styled(Box)<{ cursor?: string }>(({ theme, cursor }) => ({
  borderRadius: 4,
  padding: '4px 4px',
  background: theme.custom.themeColors.accent[20],
  cursor,
}));

const SiteItem: FC<ISiteItemProps> = ({
  site,
  warnings,
  onEdit,
  onEditCultivationArea,
  onDelete,
}) => {
  const hasWarnings = warnings.length > 0;

  return (
    <DatasetItem key={site.id} display="flex" alignItems="center" gap={1}>
      <Icon
        name={hasWarnings ? 'error-filled' : 'check-filled'}
        size="large"
        color={hasWarnings ? 'yellow' : 'green'}
      />
      <CardContainer padding={1.5}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {!!site.image ? (
              <Image width={44} height={44} image={site.image} />
            ) : (
              <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
              </Box>
            )}
            <Box ml={1.5}>
              <Box display="flex" alignItems="center" gap={0.5}>
                <ThemeTypography variant="ITEM_TITLE">{site.title}</ThemeTypography>
                <Box ml={0.5} />
                {hasWarnings && (
                  <>
                    <NumberCyrcle>
                      <ThemeTypography variant="BODY_SMALL_BOLD" color="GRAY_100">
                        {warnings.length}
                      </ThemeTypography>
                    </NumberCyrcle>
                    <WarningTextContainer>
                      <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                        {warnings[0].errorMessage}
                      </ThemeTypography>
                    </WarningTextContainer>
                    {warnings.length > 1 && (
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: warnings
                                .slice(1)
                                .map(warning => warning.errorMessage)
                                .join('; <br />'),
                            }}
                          />
                        }
                      >
                        <WarningTextContainer cursor="default">
                          <ThemeTypography variant="BODY_SMALL" color="GRAY_100">
                            ...
                          </ThemeTypography>
                        </WarningTextContainer>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                <Icon name="tag-filled" size="small" color="gray-60" />
                <Box ml={0.5} />
                <ThemeTypography variant="LABEL_INPUT">
                  {SiteLabelKeys[site.siteType]}
                </ThemeTypography>
                <Box ml={1} />
                <Icon name="location" size="small" color="gray-60" />
                <Box ml={0.5} />
                <ThemeTypography autoOverflow variant="LABEL_INPUT">
                  {site.locationName}
                </ThemeTypography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5} mr={1}>
            <Tooltip title="Edit cultivation area">
              <IconButton onClick={() => onEditCultivationArea(site)}>
                <Icon name="polygon-mapping" size="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit site">
              <IconButton onClick={() => onEdit(site)}>
                <Icon name="edit" size="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete site">
              <IconButton onClick={() => onDelete(site)}>
                <Icon name="delete" size="medium" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </CardContainer>
    </DatasetItem>
  );
};

export default SiteItem;
