import React from 'react';
import { Grid } from '@mui/material';
import { PageTitle, PageSubTitle, ErrorState } from 'components/Structure';
import { SupplyChainInspiration, HomeDocumentOverview } from 'components/Home';
import { useDialog, usePageTitle } from 'components/hooks';
import { PageContainer } from 'components/Structure';
import { EmptyStateCard } from 'designSystem';
import { BackgroundTypes, ItemTypes, AvailableSizes } from 'types/enums';
import SeedtraceIntro from 'components/Home/SeedtraceIntro';
import { Upload } from '@styled-icons/bootstrap/Upload';
import { useQuery } from '@apollo/client';
import { GET_PARTNER_USER_HOME_DATA } from 'graphql/queries';
import { removeGraphConnections } from 'utils/graphql.utils';
import { IDocument } from 'types/document.types';
import { GetPartnerUserHomeDataValues } from 'graphql/queries/partner.queries';
import { PartnerRequest } from 'types/partner.types';
import { HomeRequestsOverview } from 'components/Home';
import { ReceivedRequestCard } from 'components/Home/Requests';
import { Loader } from 'components/Forms';
import ActivityOverview from './ActivityOverview';

const PartnerHome: React.FC = () => {
  usePageTitle('Home');

  const { openDialog } = useDialog();

  const openUploadDocumentDialog = () => {
    openDialog({ type: 'UPLOAD_DOCUMENTS' });
  };

  const {
    data,
    loading: loadingDocuments,
    error,
  } = useQuery<GetPartnerUserHomeDataValues>(GET_PARTNER_USER_HOME_DATA, {
    variables: {
      first: 4,
      filters: {
        requestStatus: ['REQUESTED', 'EDITED'],
      },
    },
  });
  const documents: IDocument[] = data ? removeGraphConnections(data.documents) : [];
  const requests: PartnerRequest[] = data ? removeGraphConnections(data.partnerRequests) : [];
  const hasRequests: boolean = requests.length > 0;
  const showDocuments = !!documents.length;

  const showSupplyChainCard = !!documents.length;
  const showGettingStarted = !documents.length;
  const showWhatIsSeedtrace = !documents.length;

  if (loadingDocuments) return <Loader />;

  if (error) return <ErrorState />;

  return (
    <PageContainer>
      <PageTitle title="Home" mb={2} />
      <Grid container spacing={3}>
        {hasRequests && (
          <Grid item xs={12}>
            <HomeRequestsOverview
              requests={requests}
              renderRequestCard={request => (
                <ReceivedRequestCard request={request} descriptionHeight={40} key={request.id} />
              )}
              partnerUser={true}
            />
          </Grid>
        )}

        {showWhatIsSeedtrace && (
          <Grid item xs={12}>
            <PageSubTitle title="What is seedtrace?" mb={1} />
            <SeedtraceIntro />
          </Grid>
        )}

        {showGettingStarted && (
          <Grid item xs={6}>
            <PageSubTitle title="Getting Started" mb={1} />
            <EmptyStateCard
              onCreateItemClick={openUploadDocumentDialog}
              variant={ItemTypes.DOCUMENT}
              showSpark={false}
              background={BackgroundTypes.WORLD}
              buttonText="Upload new"
              size={AvailableSizes.MEDIUM}
              title="Add documents & certificates to prove your sustainable impact"
              buttonIcon={<Upload size={14} />}
              data-testid="documents-getting-started-card"
              data-testid-button="create-documents-btn-getting-started"
            />
          </Grid>
        )}
        <ActivityOverview />
        {showDocuments && (
          <Grid item xs={12} lg={6}>
            <HomeDocumentOverview documents={documents} loading={loadingDocuments} />
          </Grid>
        )}

        {showSupplyChainCard && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <PageSubTitle title="Inspiration" mb={1} />
                <SupplyChainInspiration />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};

export default PartnerHome;
