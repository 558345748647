import { gql } from '@apollo/client';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';

export const FIRST_MILE_IMPORT_FRAGMENT = gql`
  fragment firstMileImportValues on FirstMileImport {
    id
    title
    createdTimestamp
    modifiedTimestamp
    createdBy {
      ...basePartnerCompanyValues
    }
    language
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
`;
