export const appQueryParams = {
  productStatus: 'status',
  query: 'query',
  openNode: 'node',
  reviewMode: 'showValidation',
  order: 'order',
  orderBy: 'orderBy',
  selectedTimeFrame: 'selectedTimeFrame',
  selectedTimeFrameStart: 'start',
  selectedTimeFrameEnd: 'end',
  productStep: 'step',
  maintenance: 'maintenance',
  mediaLibrary: 'showLibrary',
  registerToken: 'token',
  lang: 'lang',
  createNewRequest: 'createNewRequest',
  requestReplyOpen: 'requestReplyOpen',
  chainRequestReplyOpen: 'chainRequestReplyOpen',
  dismissRequest: 'dismissRequest',
  requestId: 'requestId',
  new: 'new',
  tour: 'tour',
  step: 'step',
  addLanguage: 'addLanguage',
  type: 'type',
  siteViewOption: 'viewOption',
};
