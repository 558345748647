import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import {
  DatasetSelectionFormValues,
  datasetSelectionSchema,
} from 'constants/schemas/riskAnalysis.schema';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import DatasetSelection from '../../DatasetSelection';

const SelectDatasets: FC = () => {
  const { statement, navigateToNextSubSection, setFormData } = useEudrComplianceGuide();

  const handleSubmit = (values: DatasetSelectionFormValues) => {
    setFormData(values);
    navigateToNextSubSection();
  };

  return (
    <Formik<DatasetSelectionFormValues>
      validationSchema={datasetSelectionSchema}
      initialValues={datasetSelectionSchema.default()}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, isValid, submitForm }) => (
        <Form>
          <EudrComplianceGuideSubSection
            allowNextStepNavigation={isValid}
            onNextStepClick={submitForm}
          >
            <DatasetSelection
              datasets={statement.datasets}
              selectedDatasetIds={values.datasetIds}
              onSelect={selection => setFieldValue('datasetIds', selection)}
            />
          </EudrComplianceGuideSubSection>
        </Form>
      )}
    </Formik>
  );
};

export default SelectDatasets;
