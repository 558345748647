/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { FetchResult } from '@apollo/client';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import { useFeatureFlags } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { Percentage } from 'designSystem/Navigation/Stepper/Stepper';
import { IUpdateStatementInput } from 'graphql/mutations/types/compliance-mutation.types';
import useEudrComplianceStatements from 'hooks/useEudrComplianceStatements';
import React, {
  FC,
  createContext,
  createElement,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import {
  EudrAffectednessType,
  EudrStatementStatus,
  EudrStatement,
  IComplianceGuideSection,
  IComplianceGuideSubSection,
} from 'types/compliance.types';
import ComplianceGuideContent from './ComplianceGuideContent';
import ComplianceReducer from './ComplianceGuideReducer';
import ComplianceGuideWelcome from './ComplianceGuideWelcome';
import AffectivenessIndicator from './components/AffectivenessIndicator';
import { COMPLIANCE_GUIDES, ComplianceType } from './components/data/compliance.data';
import { SECTION_CONFIGURATION } from './utils/eudrCompliance.utils';

export interface IEUDRComplianceGuideContextContextValues {
  /** This data field can be used by the components to pass on form data */
  formData: any;
  sections: (IComplianceGuideSection & {
    disabled: boolean;
    completion: Percentage;
    count: number;
  })[];
  selectedSection?: IComplianceGuideSection & {
    disabled: boolean;
    completion: Percentage;
    count: number;
  };
  selectedStatementId: string;
  selectedSectionIndex: number;
  selectedSubSection: IComplianceGuideSubSection;
  selectedSubSectionIndex: number;
  statement: EudrStatement;
  setFormData: (formData: any) => void;
  setSelectedSection: (sectionId: string) => void;
  setSelectedSubSection: (sectionId: string) => void;
  setSectionsDisabled: (sectionIds: string[]) => void;
  setSectionsEnabled: (sectionIds: string[]) => void;
  updateSectionCompletion: (sectionId: string, completion?: Percentage) => void;
  /**
   * This will navigate to the next subsection that is enabled based on the current section & subsection
   * If the current subsection is the last one, it will navigate to the next section
   */
  navigateToNextSubSection: () => void;
  /**
   * This will navigate to the previous subsection that is enabled based on the current section & subsection
   * It will not navigate to the previous section if there is none available
   */
  navigateToPreviousSubSection: () => void;
  updateStatement: (
    id: string,
    input: IUpdateStatementInput
  ) => Promise<FetchResult<{ statement: EudrStatement }>>;
}

const EudrComplianceGuideContextContext = createContext<IEUDRComplianceGuideContextContextValues>({
  formData: undefined,
  sections: [],
  selectedStatementId: '',
  selectedSection: undefined,
  selectedSectionIndex: 0,
  selectedSubSection: {} as IComplianceGuideSubSection,
  selectedSubSectionIndex: 0,
  statement: {} as EudrStatement,
  setFormData: () => undefined,
  setSelectedSection: () => undefined,
  navigateToNextSubSection: () => undefined,
  navigateToPreviousSubSection: () => undefined,
  setSelectedSubSection: () => undefined,
  setSectionsDisabled: () => undefined,
  setSectionsEnabled: () => undefined,
  updateSectionCompletion: () => undefined,
  updateStatement: () => Promise.reject(),
});

const EudrComplianceGuideContextProvider: FC = () => {
  const {
    selectedStatementId,
    statement,
    statements,
    loading,
    error,
    updateStatement,
    navigateToStatementGuide,
  } = useEudrComplianceStatements();
  const { isFeatureEnabled } = useFeatureFlags();
  const disabledSectionAfterStep3 = isFeatureEnabled(FeatureFlag.DISABLE_EUDR_GUIDE_AFTER_STEP3);

  const details = COMPLIANCE_GUIDES[ComplianceType.EUDR];
  const initialSections = COMPLIANCE_GUIDES[ComplianceType.EUDR].sections.map(section => ({
    ...section,
    disabled: false,
    completion: 0 as Percentage,
    count: section.subSections.length,
  }));

  const [stateValues, dispatch] = useReducer(ComplianceReducer, {
    formData: undefined,
    sections: initialSections,
    selectedSubSection: undefined,
    selectedSection: undefined,
    disabledSubSections: [],
  });

  const {
    formData,
    sections,
    selectedSectionIndex,
    selectedSection,
    selectedSubSectionIndex,
    selectedSubSection,
  } = useMemo(() => stateValues, [stateValues]);

  /** This event also changes the selected subsection to the first one */
  const setFormData = (formData: any) => dispatch({ type: 'SET_FORM_DATA', formData });
  const setSelectedSection = (id: string | undefined) =>
    dispatch({ type: 'SET_SELECTED_SECTION', key: id });
  const setSelectedSubSection = (id: string) =>
    dispatch({ type: 'SET_SELECTED_SUB_SECTION', key: id });
  const setSectionsDisabled = (ids: string[]) =>
    dispatch({ type: 'SET_SECTIONS_DISABLED', keys: ids });
  const setSectionsEnabled = (ids: string[]) =>
    dispatch({ type: 'SET_SECTIONS_ENABLED', keys: ids });
  const updateSectionCompletion = (id: string, completion?: Percentage) =>
    dispatch({ type: 'UPDATE_SECTION_COMPLETION', key: id, completion });
  const setSubSectionDisabled = (ids: string[]) =>
    dispatch({ type: 'SET_SUB_SECTION_DISABLED', keys: ids });

  useEffect(() => {
    if (!statement || !selectedStatementId || formData?.doNotChangeSection) return;

    let {
      selectedSection,
      selectedSubSection,
      disabledSubSections,
      disabledSections,
      sectionsCompleted,
    } = SECTION_CONFIGURATION[statement?.status];

    setSelectedSection(selectedSection);

    sectionsCompleted?.forEach(section => updateSectionCompletion(section, 100));
    stateValues.sections
      .filter(({ key }) => !sectionsCompleted?.includes(key))
      .forEach(({ key }) => updateSectionCompletion(key, 0));

    if (selectedSubSection) {
      setSelectedSubSection(selectedSubSection);
    }

    // TODO: Delete this if the feature flag is removed
    if (disabledSections || disabledSectionAfterStep3) {
      const sectionAfterStep3 = [
        'risk-analysis',
        'partner-assessment',
        'risk-mitigation',
        'due-diligence',
      ];
      const disable: string[] = disabledSections
        ? [...disabledSections, ...(disabledSectionAfterStep3 ? sectionAfterStep3 : [])]
        : sectionAfterStep3;
      setSectionsDisabled(disable);
      // Enable the rest of the sections
      setSectionsEnabled(
        stateValues.sections.filter(({ key }) => !disable?.includes(key)).map(({ key }) => key)
      );
    } else {
      // Enable all sections
      setSectionsEnabled(stateValues.sections.map(({ key }) => key));
    }

    if (disabledSubSections) {
      setSubSectionDisabled(disabledSubSections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement]);

  if (loading || !selectedStatementId) {
    return <Loader />;
  }
  if (error || !statement) {
    return <ErrorState />;
  }
  // Welcome message for the created statement, here all sections should be disabled
  if (
    selectedSectionIndex === undefined ||
    !selectedSection ||
    selectedSubSectionIndex === undefined ||
    !selectedSubSection
  ) {
    return (
      <ComplianceGuideContent
        details={details}
        sections={stateValues.sections}
        selectValues={statements.map(({ id, title, year }) => ({
          id,
          title: `${title} - ${year}`,
        }))}
        selectedId={selectedStatementId}
        complianceAdornment={
          <AffectivenessIndicator affectednessType={statement.companyAffectednessType} />
        }
        onSelectionChange={navigateToStatementGuide}
        onMenuSectionClick={setSelectedSection}
      >
        <ComplianceGuideWelcome
          message=" Welcome to the compliance guide!"
          regulationUrl={details.regulationUrl}
          illustration={details.illustration}
          onStartGuideClick={() =>
            updateStatement(selectedStatementId, {
              status: EudrStatementStatus.EVALUATING_AFFECTEDNESS,
            })
          }
        />
      </ComplianceGuideContent>
    );
  }

  /**
   * This will navigate to the previous subsection that is enabled based on the current section & subsection
   * It will not navigate to the previous section if there is none available
   */
  const navigateToPreviousSubSection = () => {
    if (selectedSubSectionIndex - 1 >= 0) {
      setSelectedSubSection(selectedSection.subSections[selectedSubSectionIndex - 1].key);
    }
  };

  /**
   * This will navigate to the next subsection that is enabled based on the current section & subsection
   * If the current subsection is the last one, it will navigate to the next section
   */
  const navigateToNextSubSection = () => {
    if (selectedSubSectionIndex + 1 < selectedSection.subSections.length) {
      setSelectedSubSection(selectedSection.subSections[selectedSubSectionIndex + 1].key);
    } else {
      if (selectedSectionIndex + 1 >= stateValues.sections.length) {
        console.error('Can not navigate to next section, already at the last section');
        return;
      }
      // If there are no more subsections, navigate to the next section
      setSelectedSection(stateValues.sections[selectedSectionIndex + 1].key);
    }
  };

  const state = {
    // Set the values manually to avoid the typescript error of the state values being undefined
    formData,
    sections,
    selectedSectionIndex,
    selectedSection,
    selectedSubSectionIndex,
    selectedSubSection,
    selectedStatementId,
    statement,
    setFormData,
    setSelectedSection,
    setSelectedSubSection,
    setSectionsDisabled,
    setSectionsEnabled,
    updateSectionCompletion,
    updateStatement,
    navigateToNextSubSection,
    navigateToPreviousSubSection,
  };

  return (
    <EudrComplianceGuideContextContext.Provider value={state}>
      <ComplianceGuideContent
        details={details}
        selectedSectionKey={selectedSection.key}
        sections={sections}
        selectValues={statements.map(({ id, title, year }) => ({
          id,
          title: `${title} - ${year}`,
        }))}
        selectedId={state.selectedStatementId}
        complianceAdornment={
          <AffectivenessIndicator
            affectednessType={EudrAffectednessType[statement.companyAffectednessType]}
          />
        }
        onSelectionChange={navigateToStatementGuide}
        onMenuSectionClick={setSelectedSection}
      >
        {createElement(selectedSubSection.content, selectedSubSection.contentProps)}
      </ComplianceGuideContent>
    </EudrComplianceGuideContextContext.Provider>
  );
};

const useEudrComplianceGuide = () => useContext(EudrComplianceGuideContextContext);

export { EudrComplianceGuideContextProvider, useEudrComplianceGuide };

export default EudrComplianceGuideContextContext;
