import { useQuery } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { GET_CHAINS } from 'graphql/queries/chain.queries';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IBaseChain, IChainsResponse } from 'types/chain.types';
import { StringParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphql.utils';
import useComponentChainActions from './useComponentChainActions';
import { RouteParamsWithId } from 'types/router.types';

const usePartnerComponentChains = () => {
  const navigate = useNavigate();
  const { setErrorMessage } = useMessages();
  const { id: companyId } = useParams<RouteParamsWithId>();
  const { data, loading, error } = useQuery<IChainsResponse>(GET_CHAINS, {
    variables: { filters: { ownedById: companyId, chainType: 'COMPONENT' } },
  });
  const { createComponentChain } = useComponentChainActions();

  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);

  const componentChains = useMemo(
    () => (data?.chains ? removeGraphConnections(data.chains) : []),
    [data]
  );

  const filteredComponentChains: IBaseChain[] = useMemo(() => {
    const componentChainEdges = data?.chains.edges || [];
    if (!componentChainEdges) return componentChainEdges;

    if (!searchTerm?.length)
      return componentChainEdges.map(componentChainEdge => componentChainEdge.node);

    // Check if one of the output components contains the search term
    const filtered = componentChainEdges.filter(({ node: { title } }) => {
      return title.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return filtered.map(item => item.node);
  }, [searchTerm, data?.chains]);

  const handleCreateComponentChain = async () => {
    try {
      const result = await createComponentChain({
        variables: {
          input: {
            ownedById: companyId,
          },
        },
      });
      if (result?.data?.createChain?.chain?.id) {
        navigate(`/component-chains/${result.data.createChain.chain.id}/editor`);
      } else {
        setErrorMessage(`Failed to create new component chain. No component chain id returned.`);
      }
    } catch (error: unknown) {
      setErrorMessage(`Failed to create new component chain. ${error}`);
    }
  };

  return {
    componentChains,
    filteredComponentChains,
    searchTerm,
    data,
    loading,
    error,
    handleCreateComponentChain,
    setSearchTerm,
  };
};

export default usePartnerComponentChains;
