import {
  ICollectionSite,
  IDistributionSite,
  IFactorySite,
  IFarmSite,
  IForestSite,
  IGenericSite,
  IGroceryStoreSite,
  IHandlingSite,
  IManufacturingSite,
  IMineSite,
  IOriginSite,
  IPlantSite,
  IProcessingSite,
  IWarehouseSite,
} from 'types/site.types';

/*
  We need to define common keyFields for each site type to avoid cache conflicts.
  By default, Apollo uses the __typename and id fields to identify cache objects, but __typename differs for each site type.
*/
const sitePolicies = {
  Farm: {
    keyFields: ({ id }: IFarmSite) => `Site:${id}`,
  },
  GenericSite: {
    keyFields: ({ id }: IGenericSite) => `Site:${id}`,
  },
  Mine: {
    keyFields: ({ id }: IMineSite) => `Site:${id}`,
  },
  Forest: {
    keyFields: ({ id }: IForestSite) => `Site:${id}`,
  },
  CollectionSite: {
    keyFields: ({ id }: ICollectionSite) => `Site:${id}`,
  },
  OriginSite: {
    keyFields: ({ id }: IOriginSite) => `Site:${id}`,
  },
  Plant: {
    keyFields: ({ id }: IPlantSite) => `Site:${id}`,
  },
  ProcessingSite: {
    keyFields: ({ id }: IProcessingSite) => `Site:${id}`,
  },
  Factory: {
    keyFields: ({ id }: IFactorySite) => `Site:${id}`,
  },
  ManufacturingSite: {
    keyFields: ({ id }: IManufacturingSite) => `Site:${id}`,
  },
  Warehouse: {
    keyFields: ({ id }: IWarehouseSite) => `Site:${id}`,
  },
  HandlingSite: {
    keyFields: ({ id }: IHandlingSite) => `Site:${id}`,
  },
  GroceryStore: {
    keyFields: ({ id }: IGroceryStoreSite) => `Site:${id}`,
  },
  DistributionSite: {
    keyFields: ({ id }: IDistributionSite) => `Site:${id}`,
  },
};

export default sitePolicies;
