import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT } from './media.fragments';
import { ACTIVITY_FRAGMENT } from './activity.fragments';
import { BASE_PARTNER_COMPANY_FRAGMENT } from './partner.fragments';

export const CHAIN_ACTIVITY_FRAGMENT = gql`
  fragment chainActivityValues on ChainStepActivity {
    id
    incomingLinks {
      chainStepActivitySourceId
    }
    activity {
      ...activityValues
    }
    chainStepId
    pointPosition {
      x
      y
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const CHAIN_TASK_DETAILS_FRAGMENT = gql`
  fragment chainTasksDetailsValues on ChainTasksDetails {
    tasks {
      id
      title
      done
    }
    completed
    total
  }
`;

export const SUB_CHAIN_FRAGMENT = gql`
  fragment subChainValues on SubChain {
    id
    title
    childChainId
    outputStepActivitiesIds
    outputComponents {
      ownedBy {
        id
        name
      }
    }
    boundingBoxPosition {
      xMax
      xMin
      yMax
      yMin
    }
  }
`;

// This is used for the request list
export const CHAIN_MIN_BASE_FRAGMENT = gql`
  fragment chainMinBaseValues on Chain {
    id
    title
    image {
      ...imageVariant
    }
    chainTasksDetails {
      completed
      total
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

// This is used for the component chain list without the position and sub chains
export const CHAIN_BASE_FRAGMENT = gql`
  fragment chainBaseValues on Chain {
    ...chainMinBaseValues
    inUse
    mappingInitialised
    productId
    ownedBy {
      ...basePartnerCompanyValues
    }
    involvedPartnerCompanies {
      ...basePartnerCompanyValues
    }
    partnerRequest {
      id
      requestedByUserId
      componentId
      requestTitle
      requestMessage
      requestReplyMessage
      requestStatus
      requestedToCompany {
        ...basePartnerCompanyValues
      }
      requestedFromCompany {
        ...basePartnerCompanyValues
      }
      requestType
    }
  }
  ${BASE_PARTNER_COMPANY_FRAGMENT}
  ${CHAIN_MIN_BASE_FRAGMENT}
`;

export const CHAIN_STEP_FRAGMENT = gql`
  fragment chainStepValues on ChainStep {
    id
    title
    isDeletable
  }
`;

export const CHAIN_VISIBILITY_SETTINGS_FRAGMENT = gql`
  fragment chainVisibilitySettingsValues on ChainVisibilitySettings {
    id
    chainId
    locationVisibility
  }
`;

export const CHAIN_FRAGMENT = gql`
  fragment chainValues on Chain {
    ...chainBaseValues
    chainTasksDetails {
      tasks {
        id
        title
        done
      }
      completed
      total
    }
    subChains {
      ...subChainValues
    }
    chainStepActivities {
      ...chainActivityValues
    }
    chainSteps {
      ...chainStepValues
    }
    visibilitySettings {
      ...chainVisibilitySettingsValues
    }
  }
  ${CHAIN_BASE_FRAGMENT}
  ${SUB_CHAIN_FRAGMENT}
  ${CHAIN_ACTIVITY_FRAGMENT}
  ${CHAIN_STEP_FRAGMENT}
  ${CHAIN_VISIBILITY_SETTINGS_FRAGMENT}
`;
