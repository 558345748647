import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { SpacingProps } from '@mui/system';
import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { ICON_ROOT_PATH, IconNames, SizeRecord, getIconColor } from './Icon.utils';

export type IconColor =
  | 'yellow-80'
  | 'yellow'
  | 'blue'
  | 'baby-blue'
  | 'blue-ice'
  | 'blue-frost'
  | 'white'
  | 'black'
  | 'almost-black'
  | 'gray-20'
  | 'gray-30'
  | 'gray-40'
  | 'gray-60'
  | 'gray-80'
  | 'gray-100'
  | 'red-80'
  | 'red'
  | 'green';

//  prettier-ignore
export type IconNameType = (typeof IconNames)[number];

export type IconSize =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'xxx-large';

const StyledIcon = styled(ReactSVG)<{ size: IconSize }>(({ size }) => ({
  '& div': {
    display: 'flex',
  },
  '& svg': {
    height: SizeRecord[size],
    width: SizeRecord[size],
  },
}));

export interface IIconProps extends SpacingProps {
  name: IconNameType;
  /**
   * Icon color from type or hex / string value
   */
  color?: IconColor | string;
  size?: IconSize;
}

const Icon: FC<IIconProps> = ({
  name,
  color = 'almost-black',
  size = 'medium',
  ...spacingProps
}) => {
  const theme = useTheme();

  const iconComponent = (
    <StyledIcon
      src={`${ICON_ROOT_PATH}${name}.svg`}
      size={size}
      className="ReactIcon-root"
      beforeInjection={svg => {
        svg.setAttribute('color', getIconColor(color, theme));
      }}
    />
  );

  if (!Object.keys(spacingProps).length) {
    return iconComponent;
  }

  return (
    <Box display="flex" {...spacingProps}>
      {iconComponent}
    </Box>
  );
};

export default Icon;
