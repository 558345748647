import { useQuery } from '@apollo/client';
import { useDialog } from 'components/hooks';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { GET_SITE } from 'graphql/queries/site.queries';
import { useParams } from 'react-router-dom';
import { ISite, SiteType } from 'types/site.types';

const useSite = () => {
  const { id } = useParams();
  const { openDialog } = useDialog();
  const { deleteSite } = useSiteMutations();

  const { data, loading, error } = useQuery<{
    site: ISite;
  }>(GET_SITE, {
    variables: { id },
  });

  const site = data?.site;

  const handleEditSite = (siteArg?: ISite) => {
    const siteItem = siteArg || site;

    if (siteItem?.siteType === SiteType.CLUSTER) {
      return openDialog({
        type: 'ADD_EDIT_SITE_CLUSTER',
        props: {
          siteCluster: siteItem,
        },
      });
    }

    openDialog({
      type: 'ADD_EDIT_SITE',
      props: {
        site: siteItem,
      },
    });
  };

  const handleEditCultivationArea = (siteArg?: ISite) => {
    const siteItem = siteArg || site;
    if (!siteItem) {
      console.warn('No site to edit cultivation area');
      return;
    }
    openDialog({
      type: 'ADD_EDIT_CULTIVATION_AREA',
      props: {
        site: siteItem,
      },
    });
  };

  const handleDeleteSite = (siteArg?: ISite) => {
    const siteItem = siteArg || site;
    if (!siteItem) return;

    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site',
        text: 'Are you sure you want to delete this site? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: siteItem.title,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({ variables: { id: siteItem.id } });
        },
        onCancel: () => undefined,
      },
    });
  };

  return {
    site,
    loading,
    error,
    handleEditSite,
    handleEditCultivationArea,
    handleDeleteSite,
  };
};

export default useSite;
