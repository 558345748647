import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Badge } from 'components/DataImport/DataImport.styles';
import { FlexBox } from 'components/Structure';

interface IWarningBannerProps {
  plotWarnings: { errorMessage: string; count: number }[];
  warningCount: number;
}

const Container = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(2),
  background: theme.custom.themeColors.accent[20],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const NumberCyrcle = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.custom.themeColors.accent[60],
}));

const PlotWarningBanner: FC<IWarningBannerProps> = ({ plotWarnings, warningCount }) => {
  return (
    <Container>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap={1}>
          <Icon name="warning" color="yellow-80" size="x-large" />
          <Badge severity="warning">
            <ThemeTypography variant="BODY_SMALL_BOLD" color="ALMOST_BLACK">
              {warningCount} plot warning{warningCount > 1 && 's'}
            </ThemeTypography>
          </Badge>
        </Box>
        {plotWarnings.map((warning, index) => (
          <Box display="flex" alignItems="center" mt={1} gap={1} key={`${warning}-${index}`}>
            <NumberCyrcle>
              <ThemeTypography variant="BODY_SMALL_BOLD" color="ALMOST_BLACK">
                {warning.count}
              </ThemeTypography>
            </NumberCyrcle>
            <ThemeTypography color="ALMOST_BLACK" variant="BODY_SMALL">
              {warning.errorMessage}
            </ThemeTypography>
          </Box>
        ))}
        <Box mt={1}>
          <ThemeTypography color="ALMOST_BLACK" variant="BODY_SMALL">
            If you have incomplete geo-data for EUDR deforestation analysis (e.g. single-coordinate
            farm plots without a farm size attribute or single-coordinate farm plots that are larger
            than 4 hectares), you should consider addressing this immediately. The EUDR regulation
            requires that each plot larger than 4 hectares is marked as a polygon. A polygon refers
            to a shape on the map that has multiple coordinates marking the boundaries around the
            cultivation area of a farm plot.
          </ThemeTypography>
        </Box>
      </Box>
    </Container>
  );
};

export default PlotWarningBanner;
