import { Backdrop, Box, styled } from '@mui/material';
import Computer from 'assets/img/computer-trust.svg';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC } from 'react';
import { getOperatingSystem } from 'utils/browser.utils';

const StyledBackdrop = styled(Backdrop)(() => ({
  backgroundColor: '#ffffff',
  zIndex: 9999,
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'flex-start',
}));

const ComputerImage = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(2),
  position: 'absolute',
  left: '50%',
  transform: 'translate3d(-50%, 0, 0)',
  width: '80%',
  maxHeight: `calc(100% - ${theme.spacing(5)})`,
  zIndex: 1,
}));

const TextContainer = styled('span')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 5, 0),
}));

const Title = styled('h1')(() => ({
  fontSize: 34,
  fontWeight: 700,
}));

const Tag = styled('span')(({ theme }) => ({
  margin: 4,
  padding: '2px 4px',
  borderRadius: 4,
  background: theme.custom.themeColors.grayScale[20],
  color: theme.custom.themeColors.grayScale[80],
}));

const MobileWarning: FC<{ open: boolean }> = ({ open }) => {
  const commandKey = getOperatingSystem().includes('Mac') ? <>&#8984;</> : 'CTRL';

  return (
    <StyledBackdrop open={open}>
      <TextContainer>
        <Title>Screen size not supported</Title>
        <ThemeTypography variant="BODY_LARGE">
          For the best experience with the Seedtrace platform, please open this page in a desktop
          browser. If you&apos;re already using a desktop browser and still encountering issues, it
          might be due to a small screen size or the browser window being zoomed in. To resolve
          this, use a larger screen and ensure the zoom level is set to 100% by pressing
          <Tag>{commandKey}</Tag>+<Tag>-</Tag>
        </ThemeTypography>
      </TextContainer>
      <Box position="relative" width="100%" height="100%">
        <ComputerImage src={Computer} />
      </Box>
    </StyledBackdrop>
  );
};

export default MobileWarning;
