import { AccordionDetails, AccordionSummary, Box, styled } from '@mui/material';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { Title } from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { EudrStatementStatus, RiskLevel } from 'types/compliance.types';
import { getCountryLabelFromCode } from 'utils/country.utils';
import BatchTable from '../../BatchTable';

const TotalPlotContainer = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0, 2),
  background: theme.custom.themeColors.primary[5],
}));

const DueDiligenceSummary: FC = () => {
  const { statement } = useEudrComplianceGuide();

  return (
    <>
      <EudrComplianceGuideSubSection
        allowNextStepNavigation={false}
        customNextStepButton={
          statement.status === EudrStatementStatus.COMPLETED ||
          statement.status === EudrStatementStatus.SUBMITTED_EU
            ? { text: 'Download report', startIcon: 'download', color: 'BLUE_ICE' }
            : undefined
        }
      >
        <FlexBox gap={1} mb={1}>
          <TotalPlotContainer>
            <ThemeTypography variant="BODY_SMALL_BOLD">Sep 2024 Imports</ThemeTypography>
          </TotalPlotContainer>
          <RiskBadge riskLevel={RiskLevel.LOW} />
        </FlexBox>

        <ThemeTypography variant="BODY_MEDIUM">
          Your Due diligence statement (DDS) is ready to be submitted. The DDS will be sent directly
          to the EU information system. In case of any further controls are necessary, competent
          authorities of respective country will reach out. Make sure that all your origin’s
          geo-data and batch details are included in your DDS, and confirm that you have conducted
          all the required due diligence.
        </ThemeTypography>
      </EudrComplianceGuideSubSection>

      <FlexBox mt={2} mb={1}>
        <PageSubTitle title="Origin geo-data & batch details:" />
        <TotalPlotContainer p={1}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD">13 749 plots in total</ThemeTypography>
        </TotalPlotContainer>
      </FlexBox>

      <FlexBox width="100%" flexDirection="column">
        {statement?.datasets.map(
          ({
            id,
            title,
            rawMaterial,
            originCountry,
            ownedBy: partner,
            riskStatus,
            geocBatches,
          }) => (
            <Accordion key={id}>
              <AccordionSummary>
                <FlexBox>
                  <Icon name="polygon-data" color="gray-100" size="xxx-large" mr={2} />
                  <Box display="flex" flexDirection="column">
                    <FlexBox>
                      <Title>{title}</Title>
                      <TotalPlotContainer p={0.5} mx={1}>
                        <ThemeTypography variant="BODY_SMALL_BOLD">120 plots</ThemeTypography>
                      </TotalPlotContainer>
                      {/* TODO: risk status does not exist in backend */}
                      <RiskBadge riskLevel={RiskLevel[riskStatus] || RiskLevel.LOW} />
                    </FlexBox>
                    <FlexBox>
                      <FlexBox mr={1}>
                        <Icon name="raw-material" color="gray-80" size="small" mr={0.5} />
                        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                          {rawMaterial.title}
                        </ThemeTypography>
                      </FlexBox>
                      <FlexBox mr={1}>
                        <Icon name="location" color="gray-80" size="small" mr={0.5} />
                        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                          {getCountryLabelFromCode(originCountry)}
                        </ThemeTypography>
                      </FlexBox>
                      <FlexBox>
                        <Icon name="partner" color="gray-80" size="small" mr={0.5} />
                        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                          {partner.name}
                        </ThemeTypography>
                      </FlexBox>
                    </FlexBox>
                  </Box>
                </FlexBox>
              </AccordionSummary>
              <AccordionDetails>
                <BatchTable batches={geocBatches} />
              </AccordionDetails>
            </Accordion>
          )
        )}
      </FlexBox>
    </>
  );
};

export default DueDiligenceSummary;
