import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Badge } from 'components/DataImport/DataImport.styles';
import { FlexBox } from 'components/Structure';

interface IWarningBannerProps {
  message: string;
  issueCount: number;
  severity?: 'error' | 'warning';
}

const Container = styled(FlexBox)<{ severity?: 'error' | 'warning' }>(({ theme, severity }) => ({
  gap: theme.spacing(2),
  background:
    severity === 'error' ? theme.custom.themeColors.error[20] : theme.custom.themeColors.accent[20],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const WarningBanner: FC<IWarningBannerProps> = ({ message, issueCount, severity = 'error' }) => {
  return (
    <Container severity={severity}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          <Icon
            name="warning"
            color={severity === 'error' ? 'red-80' : 'yellow-80'}
            size="x-large"
          />
          <Badge severity={severity}>
            <ThemeTypography
              variant="BODY_SMALL_BOLD"
              color={severity === 'error' ? 'RED_50' : 'BLACK'}
            >
              {issueCount} {severity === 'error' ? 'formatting error' : 'plot warning'}
              {issueCount > 1 && 's'}
            </ThemeTypography>
          </Badge>
          <ThemeTypography
            color={severity === 'error' ? 'RED_50' : 'BLACK'}
            variant="BODY_MEDIUM_BOLD"
          >
            {message}
          </ThemeTypography>
        </Box>
      </Box>
    </Container>
  );
};

export default WarningBanner;
