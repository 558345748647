import { DocumentCategories, DocumentCategoriesType } from 'types/document.types';

const DocumentCategory: Record<
  DocumentCategoriesType,
  { label: string; requireDate: boolean; yearsFromIssuedDate?: number }
> = {
  [DocumentCategories.CERTIFICATE]: {
    label: 'Certificate',
    requireDate: true,
    yearsFromIssuedDate: undefined,
  },
  [DocumentCategories.MANAGEMENT_LETTER]: {
    label: 'Management letter',
    requireDate: true,
    yearsFromIssuedDate: 2,
  },
  [DocumentCategories.SHAREHOLDER_AGREEMENT]: {
    label: 'Shareholder agreement',
    requireDate: true,
    yearsFromIssuedDate: 2,
  },
  [DocumentCategories.LABORATORY_TEST]: {
    label: 'Laboratory test',
    requireDate: true,
    yearsFromIssuedDate: undefined,
  },
  [DocumentCategories.REPORT]: {
    label: 'Report',
    requireDate: true,
    yearsFromIssuedDate: 1,
  },
  [DocumentCategories.STATEMENT]: {
    label: 'Statement',
    requireDate: false,
    yearsFromIssuedDate: undefined,
  },
  [DocumentCategories.OTHER]: {
    label: 'Other document',
    requireDate: false,
    yearsFromIssuedDate: undefined,
  },
  [DocumentCategories.GEOGRAPHICAL_FEATURES]: {
    label: 'Geographical features',
    requireDate: false,
    yearsFromIssuedDate: undefined,
  },
  [DocumentCategories.PICTURES]: {
    label: '',
    requireDate: false,
    yearsFromIssuedDate: undefined,
  },
};

export default DocumentCategory;
