import { Tooltip as MuiTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import CommunicationIcon from 'assets/img/icons/communication.svg';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { ReactNode } from 'react';
import { AvailableSizes } from 'types/enums';

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  margin: theme.spacing(0, 1),
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
}));

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    background: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(2),
    fontWeight: 500,
    boxShadow: '0 0 8px 4px rgba(0, 0, 0, 0.1)',
    color: theme.custom.themeColors.black,
  },
}))(MuiTooltip);

const HintIcon = styled('img')<{ size: number }>(({ size }) => ({
  width: size + 4,
  pointerEvents: 'none',
}));

export type TooltipVariant = 'INFO' | 'HINT';

interface Props {
  text: string | ReactNode;
  className?: string;
  size?: AvailableSizes;
  headline?: string;
  variant?: TooltipVariant;
}

const InfoTooltip: React.FC<Props> = ({
  className,
  text,
  headline,
  size = AvailableSizes.SMALL,
  variant = 'INFO',
}) => {
  return (
    <StyledTooltip
      title={
        <>
          {headline && <ThemeTypography variant="BODY_LARGE_BOLD">{headline}</ThemeTypography>}
          {typeof text === 'string' ? (
            <ThemeTypography variant="BODY_MEDIUM" color="GRAY">
              {text}
            </ThemeTypography>
          ) : (
            text
          )}
        </>
      }
      placement="right"
    >
      <Icon className={className}>
        {variant === 'INFO' ? (
          <InfoCircleFill size={getIconSize(size)} />
        ) : (
          <HintIcon size={getIconSize(size)} src={CommunicationIcon} />
        )}
      </Icon>
    </StyledTooltip>
  );
};

export default InfoTooltip;

const getIconSize = (size: AvailableSizes) => {
  switch (size) {
    case AvailableSizes.SMALL:
      return 12;
    case AvailableSizes.MEDIUM:
      return 14;
    case AvailableSizes.LARGE:
      return 16;
    default:
      return 12;
  }
};
