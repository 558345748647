import React, { FC } from 'react';
import { styled, Box, Grid } from '@mui/material';
import { FieldWrapper } from 'designSystem';
import { DropDown } from 'components/Forms';
import { CsvSeparatorType, DecimalSeparatorType, FileConfiguration } from 'types/compliance.types';
import { CSV_SEPARATOR_OPTIONS, DECIMAL_SEPARATOR_OPTIONS } from '../utils/eudrCompliance.utils';

interface ISeparatorSelectorProps {
  fileConfiguration: FileConfiguration;
  displayCsvSeparator?: boolean;
  onFileConfigurationChange: (fileConfiguration: FileConfiguration) => void;
}

const CSV_DROPDOWN_OPTIONS = Object.entries(CSV_SEPARATOR_OPTIONS).map(([key, { label }]) => ({
  key: key as CsvSeparatorType,
  label,
}));

const DECIMAL_DROPDOWN_OPTIONS = Object.entries(DECIMAL_SEPARATOR_OPTIONS).map(
  ([key, { label }]) => ({
    key: key as DecimalSeparatorType,
    label,
  })
);

const Container = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[5],
  borderRadius: 4,
  border: `1px solid ${theme.custom.themeColors.grayScale[10]}`,
  padding: theme.spacing(2),
}));

const DropdownContainer = styled('div')(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '100% !important',
    backgroundColor: '#fff !important',
  },

  '& .MuiSelect-select': {
    padding: '4px 8px !important',
    border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
    borderRadius: 4,
    backgroundColor: '#fff',
    width: '100%',
  },
}));

const SeparatorSelector: FC<ISeparatorSelectorProps> = ({
  fileConfiguration,
  displayCsvSeparator,
  onFileConfigurationChange,
}) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={displayCsvSeparator ? 6 : 12}>
          <FieldWrapper
            label="Default file decimal separator"
            width="100%"
            tooltip={{
              variant: 'INFO',
              helperText:
                'The character used to separate the integer part from the fractional part of a number. Common examples include ‘.’ (dot) in English or ‘,’ (comma) in many European countries. Ensure the chosen separator matches the formatting conventions of the data or the system you are working with.',
            }}
          >
            <DropdownContainer>
              <DropDown<{ key: DecimalSeparatorType; label: string }>
                options={DECIMAL_DROPDOWN_OPTIONS}
                // in order to display the placeholder correctly, the value must be an empty string
                currentValue={DECIMAL_DROPDOWN_OPTIONS.find(
                  option => option.key === fileConfiguration.decimalSeparator
                )}
                onChange={option => {
                  onFileConfigurationChange({
                    decimalSeparator: option.key,
                    csvSeparator: fileConfiguration.csvSeparator,
                  });
                }}
                renderItems={item => <div>{item.label}</div>}
                placeholder="Dot (Default)"
                fullWidth
              />
            </DropdownContainer>
          </FieldWrapper>
        </Grid>
        {displayCsvSeparator && (
          <Grid item xs={6}>
            <FieldWrapper
              label="Default csv separator"
              tooltip={{
                variant: 'INFO',
                helperText:
                  '“The character used to separate individual values in a CSV (Comma-Separated Values) file. Common separators include ‘,’ (comma) or ‘;’ (semicolon). Use the appropriate separator based on the regional settings or the software that will process the file.',
              }}
            >
              <DropdownContainer>
                <DropDown<{ key: CsvSeparatorType; label: string }>
                  options={CSV_DROPDOWN_OPTIONS}
                  // in order to display the placeholder correctly, the value must be an empty string
                  currentValue={CSV_DROPDOWN_OPTIONS.find(
                    option => option.key === fileConfiguration.csvSeparator
                  )}
                  onChange={option => {
                    onFileConfigurationChange({
                      csvSeparator: option.key,
                      decimalSeparator: fileConfiguration.decimalSeparator,
                    });
                  }}
                  renderItems={item => <div>{item.label}</div>}
                  placeholder="Detect automatically (Default)"
                  fullWidth
                />
              </DropdownContainer>
            </FieldWrapper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SeparatorSelector;
