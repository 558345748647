import {
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  Grid,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';
import CountrySelectField from 'components/Forms/CountrySelectField';
import { Title } from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox } from 'components/Structure';
import { useDialog } from 'components/hooks';
import {
  IEudrStatementSubmitSchemaFormValues,
  eudrStatementSubmitSchema,
} from 'constants/schemas/compliance.schema';
import { DialogDefault, FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import { Accordion } from 'designSystem/DataDisplay/Accordion/Accordion';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, Form, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import useEudrComplianceStatements from 'hooks/useEudrComplianceStatements';
import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EudrStatementStatus, RiskLevel } from 'types/compliance.types';
import { IDataset } from 'types/dataset.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { getCountryLabelFromCode } from 'utils/country.utils';
import BatchTable from '../BatchTable';

interface ISubmitEudrStatementDialogProps extends IDefaultDialogProps {
  statementId: string;
  eudrDatasets: IDataset[];
}

const TotalPlotContainer = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0, 2),
  background: theme.custom.themeColors.primary[5],
}));

const AffectivenessIndicator = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.accent[20],
  borderRadius: 4,
  padding: theme.spacing(0, 2),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.custom.colors.seedtraceOrange,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
}));

const SubmitEudrStatementDialog: FC<ISubmitEudrStatementDialogProps> = ({
  open,
  statementId,
  eudrDatasets,
  onClose,
}) => {
  const navigate = useNavigate();
  const { updateStatement } = useEudrComplianceStatements();
  const { openDialog } = useDialog();
  const [expandedId, setExpandedId] = useState<string>();

  const handleSubmit = async (values: IEudrStatementSubmitSchemaFormValues) => {
    await updateStatement(statementId, { status: EudrStatementStatus.SUBMITTED_EU });
    navigate(`/compliance/eudr/${statementId}`);
    onClose?.();
    // For now I just send the details to the next dialog, this should be done in the future in the details dialog itself
    openDialog({ type: 'EUDR_STATEMENT_DETAILS', props: { eudrDatasets, ...values } });
  };

  const handleDatasetExpandToggle = (id: string) => {
    setExpandedId(prev => (prev === id ? undefined : id));
  };

  return (
    <DialogDefault
      open={open}
      title="Submit due diligence statement"
      data-cy="add-eudr-statement-dialog"
      fullWidth
      iconName="upload"
      maxWidth="md"
      onClose={onClose}
    >
      <DialogContent>
        <FlexBox mb={1}>
          <ThemeTypography variant="BODY_LARGE_BOLD">Due diligence:</ThemeTypography>
          <Box mr={1} />
          <TotalPlotContainer>
            <ThemeTypography variant="BODY_SMALL_BOLD">Sep 2024 Imports</ThemeTypography>
          </TotalPlotContainer>
          <AffectivenessIndicator mx={1}>
            <ThemeTypography variant="BODY_MEDIUM_BOLD">Fully affected</ThemeTypography>
          </AffectivenessIndicator>
          <RiskBadge riskLevel={RiskLevel.LOW} />
        </FlexBox>
        <ThemeTypography variant="BODY_MEDIUM">
          Your Due diligence statement (DDS) is ready to be submitted. The DDS will be sent directly
          to the EU information system. In case of any further controls are necessary, competent
          authorities of respective country will reach out. Make sure that all your origin’s
          geo-data and batch details are included in your DDS, and confirm that you have conducted
          all the required due diligence.
        </ThemeTypography>

        <Formik<IEudrStatementSubmitSchemaFormValues>
          initialValues={eudrStatementSubmitSchema.default()}
          validationSchema={eudrStatementSubmitSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <Grid container spacing={2} mt={1} mb={2}>
                <Grid item xs={4}>
                  <FieldWrapper
                    label="EORI number"
                    required
                    tooltip={{
                      helperText:
                        'An Economic Operators Registration and Identification number (EORI number) is a EU registration and identification number for businesses which undertake the import or export of goods into or out of the EU. Any business or individual established in the EU needs to obtain an EORI number from their national customs authority before commencing customs activities in the EU.',
                      variant: 'INFO',
                    }}
                  >
                    <Field
                      component={TextField}
                      placeholder="DE 732 829 293 00072"
                      size="small"
                      name="eoriNumber"
                      fullWidth
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={4}>
                  <CountrySelectField
                    label="Importing to"
                    isRequired
                    helperText="Select which EU country that the products will be imported to, of which this due diligence statement refers to."
                    placeholder="Select EU country"
                    size="small"
                    name="importToCountry"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FieldWrapper
                    label="Internal DDS number"
                    tooltip={{
                      helperText:
                        'If you have an internal Due diligence statement (DDS) number, you can add that here. This will allow other parties to find your statement and refer to it in the EU Information system.',
                      variant: 'INFO',
                    }}
                  >
                    <Field
                      component={TextField}
                      placeholder="273 634 442"
                      size="small"
                      name="internalDdsNumber"
                      fullWidth
                    />
                  </FieldWrapper>
                </Grid>

                <Grid item xs={12}>
                  <FlexBox mt={2}>
                    <ThemeTypography variant="BODY_LARGE_BOLD">
                      Origin geo-data & batch details:
                    </ThemeTypography>
                    <TotalPlotContainer ml={1}>
                      <ThemeTypography variant="BODY_MEDIUM_BOLD">
                        13 749 plots in total
                      </ThemeTypography>
                    </TotalPlotContainer>
                  </FlexBox>

                  <FlexBox
                    width="100%"
                    flexDirection="column"
                    my={2}
                    mb={3}
                    maxHeight="50vh"
                    overflow="auto"
                  >
                    {eudrDatasets.map(
                      ({
                        id,
                        title,
                        rawMaterial,
                        originCountry,
                        ownedBy: partner,
                        riskStatus,
                        geocBatches,
                      }) => (
                        <Accordion key={id} expanded={id === expandedId}>
                          <AccordionSummary onClick={() => handleDatasetExpandToggle(id)}>
                            <FlexBox>
                              <Icon name="polygon-data" color="gray-100" size="xx-large" mr={2} />
                              <Box display="flex" flexDirection="column">
                                <FlexBox>
                                  <Title>{title}</Title>
                                  <TotalPlotContainer p={0.5} mx={1}>
                                    <ThemeTypography variant="BODY_SMALL_BOLD">
                                      120 plots
                                    </ThemeTypography>
                                  </TotalPlotContainer>
                                  {/* TODO: risk status does not exist in backend */}
                                  <RiskBadge riskLevel={riskStatus || RiskLevel.LOW} />
                                </FlexBox>
                                <FlexBox>
                                  <FlexBox mr={1}>
                                    <Icon
                                      name="raw-material"
                                      color="gray-80"
                                      size="small"
                                      mr={0.5}
                                    />
                                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                                      {rawMaterial.title}
                                    </ThemeTypography>
                                  </FlexBox>
                                  <FlexBox mr={1}>
                                    <Icon name="location" color="gray-80" size="small" mr={0.5} />
                                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                                      {getCountryLabelFromCode(originCountry)}
                                    </ThemeTypography>
                                  </FlexBox>
                                  <FlexBox>
                                    <Icon name="partner" color="gray-80" size="small" mr={0.5} />
                                    <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                                      {partner.name}
                                    </ThemeTypography>
                                  </FlexBox>
                                </FlexBox>
                              </Box>
                            </FlexBox>
                          </AccordionSummary>
                          <AccordionDetails>
                            <BatchTable batches={geocBatches} />
                          </AccordionDetails>
                        </Accordion>
                      )
                    )}
                  </FlexBox>

                  <FieldWrapper>
                    <FlexBox>
                      <Field component={Checkbox} size="small" name="confirmed" fullWidth />
                      <ThemeTypography variant="BODY_MEDIUM">
                        By submitting this due diligence statement the operator confirms that due
                        diligence according to{' '}
                        <StyledLink
                          target="_blank"
                          to="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32023R1115#d1e1773-206-1"
                        >
                          Regulation 2023/1115
                        </StyledLink>{' '}
                        was carried out and that no or only a negligible risk was found that the
                        relevant products do not comply with Article 3, point (a) or (b), of that
                        Regulation.
                      </ThemeTypography>
                    </FlexBox>
                  </FieldWrapper>
                </Grid>
              </Grid>

              <DialogActions>
                <ThemeButton onClick={onClose} color="BLUE_ICE">
                  Cancel
                </ThemeButton>
                <ThemeButton
                  type="submit"
                  startIcon={<Icon name="upload" />}
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Submit statement
                </ThemeButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </DialogDefault>
  );
};

export default SubmitEudrStatementDialog;
