import { useMutation } from '@apollo/client';
import { Box, styled } from '@mui/material';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import {
  IGeoMappingFormValues,
  geoDataCustomValidation,
  geoMappingSchema,
  mappedColumnsSchema,
} from 'constants/schemas/geoUpload.schema';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import { Form, Formik, FormikProps } from 'formik';
import { PROCESS_DATASETS } from 'graphql/mutations/dataset.mutations';
import { IProcessDatasetInput } from 'graphql/mutations/types/dataset-mutation.types';
import omit from 'lodash/omit';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import {
  DatasetColumnType,
  DatasetMapping,
  DatasetStatus,
  IProcessDatasetsPayload,
} from 'types/dataset.types';
import DatasetMappingForm from '../../Forms/DatasetMappingForm';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const MapGeoDataColumns: FC = () => {
  const formRef = useRef<FormikProps<IGeoMappingFormValues>>(null);
  const { formData, statement, setSelectedSubSection } = useEudrComplianceGuide();

  const [processDatasets] = useMutation<
    { processDatasets: IProcessDatasetsPayload },
    { inputs: IProcessDatasetInput[] }
  >(PROCESS_DATASETS);

  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);

  useEffect(() => {
    if (!statement) {
      return;
    }
    // find the first data sheet that is has the status 'MAPPED_FALIED'
    const failedDataSetIndex = statement.datasets.findIndex(
      dataset => dataset.status === DatasetStatus.MAPPING_FAILED
    );
    if (failedDataSetIndex !== -1) {
      setSelectedDatasetIndex(failedDataSetIndex);
    }
  }, [statement]);

  const initialValues: IGeoMappingFormValues = useMemo(() => {
    if (!statement || !formData?.datasetMappings) {
      return geoMappingSchema.default();
    }

    const schema = statement.datasets.map(dataset => {
      const mapping = (formData?.datasetMappings as DatasetMapping[])?.find(
        mapping => mapping.datasetId === dataset.id
      );
      if (!mapping) {
        return {
          datasetId: dataset.id,
          mappedColumns: [],
        };
      }
      return {
        datasetId: mapping.datasetId,
        mappedColumns: mapping.columnsData.map(column => {
          return {
            ...mappedColumnsSchema.default(),
            sourceColumnName: column.columnName,
            columnDataExample: column.columnDataExample,
          };
        }),
      };
    });

    return schema;
  }, [statement, formData]);

  const handleClickDataSheet = (index: number) => {
    setSelectedDatasetIndex(index);
  };

  const handleSubmit = async (values: IGeoMappingFormValues) => {
    try {
      const inputs = values.map(({ datasetId, mappedColumns }) => ({
        datasetId,
        // Remove the excluded columns and the columnDataExample
        mappedColumns:
          mappedColumns
            ?.filter(({ removed }) => !removed) // remove the excluded columns
            .map(
              mapped =>
                omit<{ targetColumnName: DatasetColumnType; sourceColumnName: string }>(mapped, [
                  'columnDataExample',
                  'removed',
                ]) as { targetColumnName: DatasetColumnType; sourceColumnName: string }
            ) || [],
      }));
      const response = await processDatasets({
        variables: {
          inputs,
        },
      });
      const containsAnyTableRepresentation = response.data?.processDatasets?.datasets.some(
        ({ tableRepresentation }) => !!tableRepresentation?.length
      );
      if (containsAnyTableRepresentation) {
        setSelectedSubSection('validate');
      } else {
        setSelectedSubSection('confirmation');
      }
    } catch (error) {
      console.error(error);
    } finally {
      formRef.current?.setSubmitting(false);
    }
  };

  return (
    <Formik<IGeoMappingFormValues>
      validationSchema={geoMappingSchema}
      initialValues={initialValues}
      validate={geoDataCustomValidation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, submitForm, errors }) => (
        <Form>
          <EudrComplianceGuideSubSection
            allowNextStepNavigation={isValid}
            nextStepLoading={isSubmitting}
            onNextStepClick={submitForm}
          >
            <Box display="flex" gap={2} flexWrap="wrap">
              {statement.datasets.map((dataset, index) => (
                <DatasetContainer
                  key={dataset.id}
                  onClick={() =>
                    dataset.status === DatasetStatus.MAPPED
                      ? undefined
                      : handleClickDataSheet(index)
                  }
                  disabled={Booleanish(dataset.status === DatasetStatus.MAPPED)}
                >
                  <DatasetItem
                    title={dataset.title}
                    commodity={dataset.rawMaterial.title}
                    location={dataset.originCountry}
                    owner={dataset.ownedBy.name}
                    active={index === selectedDatasetIndex}
                    status={
                      dataset.status === DatasetStatus.MAPPED
                        ? 'valid'
                        : errors[index]
                        ? 'error'
                        : undefined
                    }
                  />
                </DatasetContainer>
              ))}
            </Box>
          </EudrComplianceGuideSubSection>

          <DatasetMappingForm datasetIndex={selectedDatasetIndex} />
        </Form>
      )}
    </Formik>
  );
};

export default MapGeoDataColumns;
