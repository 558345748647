import { imageSchema } from 'constants/schemas/shared';
import { IProcessDatasetInput } from 'graphql/mutations/types/dataset-mutation.types';
import { CsvSeparatorType, DecimalSeparatorType } from 'types/compliance.types';
import { DatasetColumn, DatasetColumnType } from 'types/dataset.types';
import { IBasePartnerCompany } from 'types/partner.types';
import * as Yup from 'yup';

export type IDatasetFormValue = {
  // This only exists if the dataset was already created in the backend
  datasetId?: string;
  title: string;
  rawMaterialId: string;
  countryCode: string;
  ownedBy: IBasePartnerCompany;
};

export type IGeoUploadFormValue = IDatasetFormValue & {
  /**
   * This is used to keep track of the files that were already uploaded and attached to the datasets and are not of the upload context
   */
  files: { id: string; title: string; inUploadContext: boolean }[];
  allowMultipleFiles: boolean;
  fileConfiguration: {
    csvSeparator?: CsvSeparatorType;
    decimalSeparator?: DecimalSeparatorType;
  };
};

export interface IDatasetImportFormValues {
  overwriteExisting: boolean;
  clusterSites: boolean;
}

export interface IColumnMappingValues {
  targetColumnName: DatasetColumnType;
  sourceColumnName: string;
  columnDataExample?: string[];
  removed?: boolean;
}

export type IGeoUploadFormValues = IGeoUploadFormValue[];
export type IGeoMappingFormValues = {
  datasetId: string;
  mappedColumns: IColumnMappingValues[];
}[];

export const datasetImportSchema = Yup.object().shape({
  overwriteExisting: Yup.boolean().default(true),
  clusterSites: Yup.boolean().default(true),
}) as Yup.ObjectSchema<IDatasetImportFormValues>;

export const datasetSchema = Yup.object().shape({
  datasetId: Yup.string().nullable(),
  title: Yup.string().default('').required('Please enter a title'),
  rawMaterialId: Yup.string().required('Please select a raw material'),
  countryCode: Yup.string().required('Please select a country of origin'),
  ownedBy: Yup.object()
    .shape({ id: Yup.string(), name: Yup.string(), logo: imageSchema })
    .default(undefined)
    .required('Please select a partner'),
}) as Yup.ObjectSchema<IDatasetFormValue>;

export const geoUploadDatasetSchema = Yup.object().shape({
  ...datasetSchema.fields,
  files: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        title: Yup.string(),
        inUploadContext: Yup.string(),
      })
    )
    .min(1)
    .default([]),
  allowMultipleFiles: Yup.boolean().default(false),
  fileConfiguration: Yup.object().shape({
    csvSeparator: Yup.string().nullable(),
    decimalSeparator: Yup.string().nullable(),
  }),
}) as Yup.ObjectSchema<IGeoUploadFormValue>;

export const mappedColumnsSchema = Yup.object().shape({
  targetColumnName: Yup.string(),
  sourceColumnName: Yup.string(),
  columnDataExample: Yup.array().of(Yup.string()),
  removed: Yup.boolean().default(false),
}) as Yup.ObjectSchema<IColumnMappingValues>;

export const geoMappingDatasetSchema = Yup.object().shape({
  datasetId: Yup.string(),
  mappedColumns: Yup.array().of(mappedColumnsSchema),
}) as Yup.ObjectSchema<IProcessDatasetInput>;

export const geoMappingSchema = Yup.array()
  .of(geoMappingDatasetSchema)
  .default([]) as Yup.ObjectSchema<IGeoMappingFormValues>;

export const geoUploadSchema = Yup.array()
  .of(geoUploadDatasetSchema)
  .min(1)
  .default([geoUploadDatasetSchema.default()]) as Yup.ObjectSchema<IGeoUploadFormValues>;

/**
 * Custom validation function to check the mapping of the columns
 */
export const geoDataCustomValidation = (values: IGeoMappingFormValues) => {
  const errors = values.reduce((prev, dataset, index) => {
    const mappedColumns = dataset.mappedColumns?.filter(column => !column.removed) || [];
    // Check if all columns are filled or removed
    if (!mappedColumns.every(({ targetColumnName, removed }) => targetColumnName || removed)) {
      return {
        ...prev,
        [index]:
          'Please map each of your sheet columns to one of our template columns or remove any columns that are not needed.',
      };
    }

    // Check if all required columns exist
    if (
      mappedColumns.length < 2 ||
      !mappedColumns.some(column => column.targetColumnName === DatasetColumn.FARM_ID) ||
      (!mappedColumns.some(column => column.targetColumnName?.startsWith('COORDINATES')) &&
        (!mappedColumns.some(column => column.targetColumnName?.startsWith('LATITUDE')) ||
          !mappedColumns.some(column => column.targetColumnName?.startsWith('LONGITUDE'))))
    ) {
      return {
        ...prev,
        [index]:
          'Please add at least the columns Farm ID and your coordinates. Remove any columns that are not needed.',
      };
    }

    // Check if no duplicates exist
    if (
      mappedColumns.some(
        ({ targetColumnName }) =>
          mappedColumns.filter(column => column.targetColumnName === targetColumnName).length > 1
      )
    ) {
      return {
        ...prev,
        [index]: 'Please make sure you have selected unique columns without duplicates',
      };
    }
    return prev;
  }, {});
  return errors;
};
