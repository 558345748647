import { FormHelperText, IconButton, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Container = styled(({ error, ...props }) => <div {...props} />)(
  ({ theme, error }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    border: '1px solid',
    borderColor: error ? theme.palette.warning.main : theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    background: '#fff',
  })
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UploadContainer = styled(({ isDragActive, ...props }) => <div {...props} />)(
  ({ theme, isDragActive }) => {
    const activeStyles = {
      borderColor: theme.custom.colors.blueFrost,

      '& *': {
        color: `${theme.custom.colors.blueFrost} !important`,
        fill: theme.custom.colors.blueFrost,
      },
    };

    const defaultStyles = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      padding: theme.spacing(2),
      transition: 'all 0.125s ease',
      cursor: 'pointer',

      '& *': {
        color: theme.palette.grey[500],
        fill: theme.palette.grey[500],
      },

      '&:hover': {
        ...activeStyles,
      },
    };

    if (isDragActive) {
      return {
        ...defaultStyles,
        ...activeStyles,
      };
    }

    return defaultStyles;
  }
);

export const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
  fontSize: 12,
  marginLeft: theme.spacing(1),
  marginTop: -2,
  fontWeight: 700,
  color: theme.palette.error.main,
}));

export const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  marginLeft: theme.spacing(1),
}));

export const ProgressNumber = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 12,
}));

export const FileNameText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.text.primary,
  fontWeight: 700,
  fontSize: 14,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 300,
  lineHeight: 1,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: 10,
  fontWeight: 400,
}));

export const ActionButton = styled(IconButton)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
}));

export const ProgressContainer = styled('div')(() => ({
  textAlign: 'right',
  width: 110,
}));
