import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { TagFill } from '@styled-icons/bootstrap/TagFill';
import { ExternalLink } from '@styled-icons/evaicons-solid/ExternalLink';
import { useDialog } from 'components/hooks';
import { DialogTypes } from 'contexts/DialogContext';
import { CardContainer, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { booleanish, Booleanish } from 'types/booleanish.types';
import {
  PartnerRequest,
  PartnerRequestStatus,
  RequestType,
  RequestTypeType,
} from 'types/partner.types';
import { REQUEST_STATUS_CLIENT } from 'utils/requestStatus.utils';
import ClickablePartnerName from './ClickablePartnerName';
import DocumentCategory from 'components/DocumentLibrary/constants/documentCategory.utils';

interface IProvidedRequestCardProps {
  request: PartnerRequest;
}

export const REVIEW_REQUEST_DIALOGS: Record<RequestTypeType, DialogTypes> = {
  [RequestType.CHAIN]: 'REVIEW_CHAIN_REQUEST',
  [RequestType.DOCUMENT]: 'REVIEW_DOCUMENT_REQUEST',
  [RequestType.SELF_ASSESSMENT]: 'REVIEW_DOCUMENT_REQUEST',
};

const CategoryIcon = styled(TagFill)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const CertificationLogo = styled('img')(() => ({
  width: 'auto',
  height: 40,
}));

const LinkIcon = styled(ExternalLink)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.custom.themeColors.grayScale[100],
}));

const ClickableTitle = styled(Box)<{ clickable: booleanish }>(({ clickable }) => ({
  cursor: clickable === 'true' ? 'pointer' : 'default',
}));

const ProvidedRequestCard: FC<IProvidedRequestCardProps> = ({ request }) => {
  const { requestTitle, requestedToCompany, document, requestedToCompany: partner } = request;
  const { custom } = useTheme();
  const { openDialog } = useDialog();
  const navigate = useNavigate();

  const navigateToPartner = () => {
    navigate(`/partners/${partner.id}`);
  };

  const handleReviewAndApprove = () => {
    openDialog({
      type: REVIEW_REQUEST_DIALOGS[request.requestType],
      props: {
        request,
      },
    });
  };

  return (
    <CardContainer
      key={request.id}
      maxWidth={300}
      minWidth={300}
      padding={2}
      data-cy="provided-request-card"
    >
      <Box display="flex" flexDirection="column" gap={1} height="100%">
        <Box display="flex" alignItems="center" gap={1.5} mb={1}>
          {document?.category === 'CERTIFICATE' && document.certificateCatalogItem?.imageUrl ? (
            <CertificationLogo
              src={document.certificateCatalogItem?.imageUrl}
              alt={`logo of ${document.certificateCatalogItem?.title}`}
            />
          ) : (
            <Icon
              size="xx-large"
              name={
                REQUEST_STATUS_CLIENT[request.requestType][
                  PartnerRequestStatus[request.requestStatus]
                ].iconName
              }
            />
          )}
          <Box display="flex" flexDirection="column">
            <ClickableTitle
              display="flex"
              alignItems="center"
              flexDirection="row"
              clickable={Booleanish(!!document?.url)}
              onClick={() => (document?.url ? window.open(document?.url, '_blank') : undefined)}
            >
              <ThemeTypography variant="ITEM_TITLE">
                {document?.category === 'CERTIFICATE'
                  ? document.certificateCatalogItem?.title
                  : requestTitle}
              </ThemeTypography>
              {document?.url && <LinkIcon size={12} />}
            </ClickableTitle>
            {document?.category && (
              <Box display="flex" alignItems="center" mr={1.5} component="span">
                <CategoryIcon size={12} color={custom.themeColors.grayScale[80]} />
                <ThemeTypography color="GRAY_80" variant="BUTTON_SMALL">
                  {DocumentCategory[document.category].label}
                </ThemeTypography>
              </Box>
            )}
            {request.requestType === RequestType.CHAIN && (
              <Box display="flex" alignItems="center" mr={1.5} component="span">
                <CategoryIcon size={12} color={custom.themeColors.grayScale[80]} />
                <ThemeTypography color="GRAY_80" variant="BUTTON_SMALL">
                  Chain mapping
                </ThemeTypography>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ClickablePartnerName partnerName={requestedToCompany.name} onClick={navigateToPartner} />
          <ThemeButton
            size="medium"
            data-cy="review-request-btn"
            onClick={handleReviewAndApprove}
            startIcon={
              <Icon
                name={
                  REQUEST_STATUS_CLIENT[request.requestType][PartnerRequestStatus.EDITED].iconName
                }
              />
            }
          >
            Review
          </ThemeButton>
        </Box>
      </Box>
    </CardContainer>
  );
};

export default ProvidedRequestCard;
