import { Box } from '@mui/material';
import { DocumentRequestIcon } from 'assets/img/icons';
import emailSend from 'assets/img/icons/email-send.svg';
import { Loader, SearchToolbar } from 'components/Forms';
import PaginatedRequestsTable from 'components/Requests/PaginatedRequestsTable';
import {
  EmptySearchResults,
  ErrorState,
  PageContainer,
  PageSubTitle,
  PageTitle,
  ScrollableTableContainer,
  ActionContainer,
} from 'components/Structure';
import { usePartnerRequests } from 'components/hooks';
import { PARTNER_REQUEST_ROWS_PER_PAGE } from 'constants/pagination';
import { EmptyStateCard, ThemeButton } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, Fragment, PropsWithChildren } from 'react';
import { AvailableSizes, BackgroundTypes, ItemTypes } from 'types/enums';
import { RequestIcon } from './PartnerOverview';
import DocumentSkeleton from 'components/DocumentLibrary/DocumentSkeleton';

const PartnerRequests: FC<PropsWithChildren> = () => {
  const {
    allRequests,
    filteredRequests,
    fulfilledRequests,
    loading,
    error,
    searchTerm,
    isPartnerRegistered,
    setSearchTerm,
    handleRequestClick,
    handleActionClick,
    handleNewRequestClick,
  } = usePartnerRequests();

  if (error) {
    return <ErrorState />;
  }
  if (loading) {
    return <Loader />;
  }
  if (!allRequests.length && !searchTerm) {
    return (
      <PageContainer>
        <PageTitle title="Partner requests" goBackLabel="All partners" goBackUrl="/partners" />
        <Box mt={4}>
          <EmptyStateCard
            title={
              isPartnerRegistered
                ? 'Request partner information to manage and prove your impact'
                : 'Your partner needs to be registered on the platform to request information'
            }
            onCreateItemClick={handleNewRequestClick}
            variant={ItemTypes.DOCUMENT}
            buttonText={isPartnerRegistered ? 'Add request' : 'Re-send invite'}
            buttonIcon={
              isPartnerRegistered ? (
                <DocumentRequestIcon height={16} />
              ) : (
                <RequestIcon src={emailSend} alt="Envelop with arrow" />
              )
            }
            background={BackgroundTypes.DETAILED_DOCUMENTS}
            size={AvailableSizes.MEDIUM}
            data-testid="partner-requests-getting-started-card"
            data-testid-button="new-request-getting-started-card-btn"
          />
        </Box>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageTitle title="Partner requests" goBackLabel="All partners" goBackUrl="/partners" />

      <Fragment>
        <Box position="relative">
          <ActionContainer>
            <SearchToolbar
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              data-cy="document-search-input"
              placeholder="Search document"
              className="search-input"
            />
            <Box mr={1} />
            <ThemeButton
              data-cy="new-request-btn"
              startIcon={<Icon name="document-request-pending" />}
              onClick={handleNewRequestClick}
            >
              New request
            </ThemeButton>
          </ActionContainer>

          <ScrollableTableContainer>
            {filteredRequests.length ? (
              <PaginatedRequestsTable
                rowsPerPage={PARTNER_REQUEST_ROWS_PER_PAGE}
                requests={filteredRequests}
                onRequestClick={handleRequestClick}
                onActionClick={handleActionClick}
              />
            ) : (
              <Fragment />
            )}

            {!searchTerm && fulfilledRequests.length ? (
              <Fragment>
                {filteredRequests.length ? (
                  <PageSubTitle title="Completed requests" />
                ) : (
                  <Fragment />
                )}
                <PaginatedRequestsTable
                  data-cy="fulfilled-requests-table-body"
                  rowsPerPage={PARTNER_REQUEST_ROWS_PER_PAGE}
                  hideHeader={!!filteredRequests.length}
                  requests={fulfilledRequests}
                  onRequestClick={handleRequestClick}
                  onActionClick={handleActionClick}
                />
              </Fragment>
            ) : (
              <Fragment />
            )}
          </ScrollableTableContainer>
        </Box>

        {searchTerm.length && filteredRequests.length === 0 ? (
          <EmptySearchResults
            message="No search results found"
            skeletonComponent={<DocumentSkeleton title={searchTerm} />}
          />
        ) : (
          <Fragment />
        )}
      </Fragment>
    </PageContainer>
  );
};

export default PartnerRequests;
