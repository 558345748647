import { useLazyQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { LoadingCard } from 'components/Forms';
import { EmptyState, ErrorState } from 'components/Structure';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { GET_REACTIONS } from 'graphql/queries';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import FeedbackListItem from './FeedbackListItem';

const List = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(8),
  left: theme.spacing(2),
  right: theme.spacing(2),
  bottom: theme.spacing(2),
  overflow: 'scroll',
}));

const Action = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
}));

const FeedbackList = ({ reactions: { edges, pageInfo } }) => {
  const { id } = useParams();
  const { endCursor, hasNextPage } = pageInfo;
  const [loadMore, { loading, error }] = useLazyQuery(GET_REACTIONS, {
    variables: {
      id,
      cursor: endCursor,
    },
  });

  return (
    <LoadingCard label="Feedback">
      <List>
        {!edges.length && <EmptyState message="There was no feedback submitted yet" />}
        {!!edges.length &&
          edges.map(({ node }, index) => (
            <FeedbackListItem first={index === 0} key={node.id} reaction={node} />
          ))}
        {hasNextPage && !error && (
          <Action>
            <ThemeButton size="medium" color="BLUE_ICE" loading={loading} onClick={loadMore}>
              Load more
            </ThemeButton>
          </Action>
        )}
        {error && (
          <Action>
            <ErrorState
              center={false}
              error="Something went wrong loading more feedback. Please reload and try again or contact our support."
            />
          </Action>
        )}
      </List>
    </LoadingCard>
  );
};

FeedbackList.propTypes = {
  reactions: PropTypes.object.isRequired,
};

export default FeedbackList;
