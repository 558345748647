import React, { Fragment, ReactElement, useMemo } from 'react';
import { Formik, Form, FastField as Field } from 'formik';
import { Box, Grid, styled } from '@mui/material';
import { FieldWrapper } from 'designSystem';
import { DatePickerField, DropDown, TextInputFileExtension } from 'components/Forms';
import { documentSchema, requestDocumentSchema } from 'constants/schemas/documents';
import { FileEarmarkText } from '@styled-icons/bootstrap/FileEarmarkText';
import CertificationAutoComplete from '../CertificationAutoComplete';
import {
  DocumentCategories,
  DocumentCategoriesType,
  DocumentVisibilityStatus,
} from 'types/document.types';
import { DocumentInput } from 'types/graphql';
import documentVisibilityOptions, {
  VisibilityStatusKeyValue,
} from 'components/DocumentLibrary/constants/documentVisibility';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import DocumentCategory from '../constants/documentCategory.utils';

const CertificationContainer = styled(Grid)(({ theme }) => ({
  '&.MuiGrid-root': {
    paddingTop: theme.spacing(1.5),
  },
}));

const CertificationBox = styled(Box)(({ theme }) => ({
  background: theme.custom.colors.backgroundLight,
  borderRadius: 4,
  padding: 12,
}));

interface RenderProps {
  submitForm: () => Promise<void>;
  isSubmitting: boolean;
}

interface Props {
  onSubmit: (values: DocumentInput) => void;
  issuedDate: Date;
  expiryDate: Date;
  category: DocumentCategories;
  certificateCatalogItemId: string;
  title: string;
  visibility: keyof typeof DocumentVisibilityStatus;
  children: (renderProps: RenderProps) => ReactElement;
  isRequestDocument?: boolean;
}

const EditForm: React.FC<Props> = ({
  title,
  issuedDate,
  expiryDate,
  category,
  certificateCatalogItemId,
  visibility,
  onSubmit,
  children = () => null,
  isRequestDocument = false,
}) => {
  const documentCategoryOptions = Object.values(DocumentCategories);
  const { isFeatureEnabled } = useFeatureFlags();
  const visibilitySettingEnabled = isFeatureEnabled(FeatureFlag.DOCUMENT_VISIBILITY_SETTING);
  const visibilityOptions = useMemo(() => {
    if (isRequestDocument)
      return documentVisibilityOptions.filter(option => option.key !== 'PRIVATE');

    return documentVisibilityOptions;
  }, [isRequestDocument]);

  return (
    <Formik<DocumentInput>
      initialValues={{
        issuedDate,
        expiryDate,
        title,
        category,
        certificateCatalogItemId,
        // set initial value to visibility public if it is a request document.
        // By default all documents are set to private when uploading. This is not intended if document are getting shared.
        visibility: isRequestDocument ? 'PUBLIC' : visibility,
      }}
      validationSchema={isRequestDocument ? requestDocumentSchema : documentSchema}
      enableReinitialize
      onSubmit={values => onSubmit(values)}
    >
      {({ submitForm, isSubmitting, setFieldValue, values, errors }) => {
        const category: DocumentCategoriesType = values.category;
        // display the certification field if document category is set to certificate
        const isCertificate = category === DocumentCategories.CERTIFICATE;
        const disableDateFields = !category;

        // For some documents the expiry date is dependent on the issued date.
        // This is why we are checking how many years the document has been valid for
        const yearsFromIssuedDate = category
          ? DocumentCategory[category]?.yearsFromIssuedDate
          : undefined;

        return (
          <Fragment>
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Box mr={1}>
                      <FileEarmarkText size={35} />
                    </Box>
                    <Field
                      component={TextInputFileExtension}
                      name="title"
                      variant="outlined"
                      fullWidth
                      data-cy="document-name-input"
                    />
                  </Box>
                </Grid>
                <Grid item xs={visibilitySettingEnabled ? 6 : 12}>
                  <FieldWrapper label="Document type">
                    <Field
                      component={DropDown}
                      errorMsg={errors['category']}
                      name="category"
                      label="Select type"
                      variant="outlined"
                      fullWidth
                      currentValue={values?.category}
                      options={documentCategoryOptions}
                      onChange={(item: DocumentCategoriesType) => setFieldValue('category', item)}
                      data-cy="document-category-dropdown"
                      renderItems={(item: DocumentCategoriesType) => (
                        <div>{DocumentCategory[item].label}</div>
                      )}
                    />
                  </FieldWrapper>
                </Grid>
                {visibilitySettingEnabled && (
                  <Grid item xs={6}>
                    <FieldWrapper
                      label="Confidentiality"
                      tooltip={{
                        helperText: (
                          <div>
                            <p>
                              The confidentiality level decides who can view your document. Only
                              users from your organisation can edit this setting.
                            </p>
                            <p>
                              <b>Public</b>: Visible to everyone, including consumers when document
                              is used as proof for an impact claim.
                            </p>

                            {/* <p>
                              <b>Partners</b>: Allows actors you are connected to in the platform to view the document, including seedtrace. The contents of the document is hidden for consumers and any other external users.
                            </p> */}

                            <p>
                              <b>Private</b>: Users from your organisation can view this document.
                              It can also be reviewed by seedtrace when used as proof for impact
                              claims, but is hidden to all others.
                            </p>
                          </div>
                        ),
                        variant: 'INFO',
                      }}
                    >
                      <Field
                        component={DropDown}
                        name="visibility"
                        variant="outlined"
                        fullWidth
                        currentValue={values?.visibility}
                        options={visibilityOptions}
                        keyField="key"
                        onChange={(item: DocumentVisibilityStatus) =>
                          setFieldValue('visibility', item)
                        }
                        data-cy="document-visibility-dropdown"
                        renderItems={(item: VisibilityStatusKeyValue) => (
                          <Box display="flex" gap={1.5} justifyContent="center">
                            <span>{item.icon}</span>
                            <span>{item.value}</span>
                          </Box>
                        )}
                      />
                    </FieldWrapper>
                  </Grid>
                )}

                {isCertificate && (
                  <CertificationContainer item xs={12}>
                    <CertificationBox>
                      <FieldWrapper label="Select certification">
                        <Field
                          name="certificateCatalogItemId"
                          component={CertificationAutoComplete}
                        />
                      </FieldWrapper>
                    </CertificationBox>
                  </CertificationContainer>
                )}
                <Grid item xs={6}>
                  <FieldWrapper label="Issued Date">
                    <DatePickerField
                      disabled={disableDateFields}
                      name="issuedDate"
                      placeholder="Select date"
                      data-cy="date-picker-issued-date"
                      placement="TOP"
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FieldWrapper
                    label="Expiry Date"
                    info={
                      !!yearsFromIssuedDate
                        ? `${yearsFromIssuedDate} year(s) validity period`
                        : undefined
                    }
                  >
                    <DatePickerField
                      disabled={disableDateFields || !!yearsFromIssuedDate}
                      name="expiryDate"
                      placeholder="Select date"
                      data-cy="date-picker-expiry-date"
                      dependentField="issuedDate"
                      yearsAddedFromDependentField={yearsFromIssuedDate}
                      placement="TOP"
                      align="LEFT"
                    />
                  </FieldWrapper>
                </Grid>
              </Grid>
            </Form>
            {children({
              submitForm,
              isSubmitting,
            })}
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default EditForm;
