import { useQuery } from '@apollo/client';
import { GET_DATASET } from 'graphql/queries/dataset.queries';
import { useParams } from 'react-router-dom';
import { IDataset } from 'types/dataset.types';

const useDatasetImportOverview = () => {
  // In the partner library the partnerId is set
  const { id: partnerId, datasetId } = useParams<{ id?: string; datasetId?: string }>();

  const { data, loading, error } = useQuery<{ dataset: IDataset }>(GET_DATASET, {
    variables: { id: datasetId },
  });

  const dataset = data?.dataset;

  return { dataset, loading, error, isPartnerLibrary: !!partnerId };
};

export default useDatasetImportOverview;
