import { ImageVariant } from './media.types';
import { CertificateCatalogItem } from './types';

export enum DocumentCategories {
  'MANAGEMENT_LETTER' = 'MANAGEMENT_LETTER',
  'CERTIFICATE' = 'CERTIFICATE',
  'SHAREHOLDER_AGREEMENT' = 'SHAREHOLDER_AGREEMENT',
  'PICTURES' = 'PICTURES',
  'LABORATORY_TEST' = 'LABORATORY_TEST',
  'REPORT' = 'REPORT',
  'STATEMENT' = 'STATEMENT',
  'GEOGRAPHICAL_FEATURES' = 'GEOGRAPHICAL_FEATURES',
  'OTHER' = 'OTHER',
}

export interface DocumentCategoryMapping {
  MANAGEMENT_LETTER: 'Management letter';
  CERTIFICATE: 'Certificate';
  SHAREHOLDER_AGREEMENT: 'Shareholder agreement';
  PICTURES: 'Pictures';
  LABORATORY_TEST: 'Laboratory test';
  REPORT: 'Report';
  STATEMENT: 'Statement';
  GEOGRAPHICAL_FEATURES: 'Geographical features';
  OTHER: 'Other document';
}

export enum DocumentStatus {
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
}

export enum DocumentVisibilityStatus {
  PRIVATE = 'Private',
  // Disabled for now until we develop the partner autocomplete
  // SELECTED = 'Partners',
  PUBLIC = 'Public',
}

export type DocumentCategoriesType = `${keyof typeof DocumentCategories}`;

export type DocumentCategoryMappingType = `${keyof DocumentCategoryMapping}`;

export type DocumentStatusType = `${DocumentStatus}`;

export type DocumentVisibilityStatusType = `${keyof typeof DocumentVisibilityStatus}`;

// TODO: Very bad naming since its also referring to the DOM Document
// We should use the standard prefix method for all our interfaces and types soon
export interface IDocument {
  id: string;
  title: string;
  path: string;
  url: string;
  expiryDate?: Date;
  issuedDate?: Date;
  category?: DocumentCategoriesType;
  createdTimestamp: Date;
  status: DocumentStatusType;
  impactClaimCount?: number;
  usageCount?: number;
  certificateCatalogItem?: CertificateCatalogItem;
  visibility: DocumentVisibilityStatusType;
  createdBy: {
    id: string;
    name: string;
    logo?: ImageVariant;
  };
}
