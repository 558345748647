import { gql } from '@apollo/client';
import { EUDR_STATEMENT } from 'graphql/fragments/compliance.fragments';

export const CREATE_STATEMENT = gql`
  mutation CreateStatement($input: CreateStatementInput!) {
    createStatement(input: $input) {
      statement {
        ...eudrStatementValues
      }
    }
  }
  ${EUDR_STATEMENT}
`;

export const UPDATE_STATEMENT = gql`
  mutation UpdateStatement($id: UUID!, $input: UpdateStatementInput!) {
    updateStatement(id: $id, input: $input) {
      statement {
        ...eudrStatementValues
      }
    }
  }
  ${EUDR_STATEMENT}
`;

export const DELETE_STATEMENTS = gql`
  mutation DeleteStatements($ids: [UUID]!) {
    deleteStatements(ids: $ids) {
      ids
    }
  }
`;

export const SUBMIT_EUDR_RISK_ANALYSIS = gql`
  mutation SubmitEUDRRiskAnalysis($input: SubmitEUDRRiskAnalysisInput!) {
    submitEUDRRiskAnalysis(input: $input) {
      message
    }
  }
`;
