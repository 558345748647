import React, { Fragment, useMemo } from 'react';
import { Loader, SearchToolbar } from 'components/Forms';
import { Box, Grid } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import { useQuery } from '@apollo/client';
import { GET_ALL_PRODUCTS } from 'graphql/queries';
import { Plus } from '@styled-icons/bootstrap/Plus';
import {
  PageSubTitle,
  PageTitle,
  EmptySearchResults,
  ScrollableTableContainer,
  PageContainer,
  ActionContainer,
} from 'components/Structure';
import { useConfig, usePageTitle, useProductActions } from 'components/hooks';
import { ActiveProductTable, ArchivedProductTable } from 'components/Product';
import { ProductSummarySkeleton } from 'components/Product/ProductTable';
import { styled } from '@mui/material/styles';
import { GettingStarted, NextStepWebshop, HomeNextSteps } from 'components/Home';
import { sortOnContentStatus, getOverallStatus, getHomeTitle, getProductsInDraft } from 'utils';
import isEmpty from 'lodash/isEmpty';
import { EmptyStateCard, CardIllustration } from 'designSystem';
import { BackgroundTypes, ItemTypes, AvailableSizes } from 'types/enums';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
  marginTop: 16,

  '& .MuiGrid-item': {
    display: 'flex',
  },
}));

const Products = () => {
  usePageTitle('Product pages');
  const { handleCreateProduct } = useProductActions();
  const { appQueryParams } = useConfig();

  const { data = {}, loading } = useQuery(GET_ALL_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  });

  const { products: { edges: products } = {}, archivedProducts: { edges: archivedProducts } = {} } =
    data;

  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);

  const filteredProducts = useMemo(() => {
    if (!products) return products;

    if (!searchTerm?.length) return products.map(item => item.node);

    const filtered = products.filter(
      ({ node: { title, articleNumber } }) =>
        title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        articleNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

    return filtered.map(item => item.node);
  }, [searchTerm, products]);

  const filterArchivedProducts = useMemo(() => {
    if (!archivedProducts) return archivedProducts;

    if (!searchTerm?.length) return archivedProducts.map(item => item.node);

    const filtered = archivedProducts.filter(
      ({ node: { title, articleNumber } }) =>
        title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        articleNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

    return filtered.map(item => item.node);
  }, [searchTerm, archivedProducts]);

  const draftProducts = useMemo(() => {
    return filteredProducts ? getProductsInDraft(filteredProducts) : [];
  }, [filteredProducts]);

  const highlightedProduct = useMemo(() => {
    return draftProducts.length ? sortOnContentStatus(draftProducts)[0] : null;
  }, [draftProducts]);

  const overallStatusDraftProducts = useMemo(
    () => getOverallStatus(draftProducts),
    [draftProducts]
  );

  const gettingStartedTitle = useMemo(
    () => getHomeTitle(overallStatusDraftProducts),
    [overallStatusDraftProducts]
  );

  const overallContentStatus = getOverallStatus(filteredProducts);
  const productPublishedSingleLanguage = useMemo(() => {
    if (filteredProducts) {
      return filteredProducts.find(
        product => product.isPublished && product.publishedLanguages.length === 1
      );
    }

    return null;
  }, [filteredProducts]);

  if (isEmpty(data) && loading) {
    return <Loader />;
  }

  if (!products?.length && !archivedProducts?.length) {
    return (
      <PageContainer>
        <PageTitle title="Product pages" />
        <Box mt={4}>
          <EmptyStateCard
            title="Create your first product to start telling the story of your supply chains"
            onCreateItemClick={handleCreateProduct}
            variant={ItemTypes.PRODUCT}
            buttonText="Add product"
            background={BackgroundTypes.WORLD}
            size={AvailableSizes.LARGE}
            data-testid="product-getting-started-card"
            renderIllustration={
              <CardIllustration
                image="LOCATIONS"
                itemType="LOCATIONS"
                width={300}
                right={25}
                top={0}
              />
            }
          />
        </Box>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageTitle title="Product pages" />
      <Container>
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm || ''}
            className="search-input"
            data-cy="product-search-input"
            placeholder="Search product"
            actions={
              <Box ml={1}>
                <ThemeButton
                  size="medium"
                  color="YELLOW"
                  onClick={handleCreateProduct}
                  startIcon={<Plus size={20} />}
                  data-cy="create-product-btn"
                >
                  Add new product
                </ThemeButton>
              </Box>
            }
          />
        </ActionContainer>
        <ScrollableTableContainer pb={1}>
          <ActiveProductTable products={filteredProducts} />
          <Box mt={2} mb={2}>
            <ArchivedProductTable
              products={filterArchivedProducts}
              hideHeader={!!filteredProducts.length}
            />
          </Box>
        </ScrollableTableContainer>
        {highlightedProduct ? (
          <Fragment>
            <PageSubTitle title="Next Steps" mb={2} />
            <Grid container spacing={2}>
              <Grid item md={12} lg={6}>
                <GettingStarted
                  products={filteredProducts}
                  highlightedProduct={highlightedProduct}
                  homeTitle={gettingStartedTitle}
                  overallStatus={overallStatusDraftProducts}
                  loading={loading}
                  size="MEDIUM"
                />
              </Grid>
              <Grid item md={12} lg={6}>
                <NextStepWebshop />
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          overallContentStatus === 'PUBLISHED' && (
            <Fragment>
              <PageSubTitle title="Next Steps" mb={2} />
              <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                  <NextStepWebshop />
                </Grid>
                <Grid item md={12} lg={6}>
                  <HomeNextSteps
                    publishedProduct={productPublishedSingleLanguage}
                    hideWebshopCard
                  />
                </Grid>
              </Grid>
            </Fragment>
          )
        )}
      </Container>
      {!filterArchivedProducts?.length && !filteredProducts.length && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Create draft',
            onClick: handleCreateProduct,
          }}
          skeletonComponent={<ProductSummarySkeleton title={searchTerm} />}
        />
      )}
    </PageContainer>
  );
};

export default Products;
