import React, { memo, ReactNode } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoTooltip, { TooltipVariant } from 'designSystem/DataDisplay/InfoTooltip/InfoTooltip';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { AvailableSizes } from 'types/enums';
import { themeColors } from 'styles/themeColors';
import Icon, { IconNameType } from 'designSystem/Primitives/Icon/Icon';

const Title = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

type FieldWrapperVariant = 'small' | 'medium' | 'large';

const Label = styled(ThemeTypography)(() => ({
  lineHeight: 1,
}));

const Info = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  lineHeight: 1,
  fontWeight: 400,
}));

const Note = styled(Typography)(() => ({
  fontSize: 14,
}));

interface Props {
  labelIcon?: IconNameType;
  label?: string;
  subtitle?: string;
  info?: string;
  inverted?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  variant?: FieldWrapperVariant;
  tooltip?: {
    helperText: string | ReactNode;
    variant: TooltipVariant;
  };
  children: React.ReactNode;
}

const FieldWrapper: React.FC<Props & BoxProps> = ({
  label,
  labelIcon,
  subtitle,
  tooltip,
  variant = 'large',
  inverted = false,
  children,
  required,
  info,
  ...rest
}) => {
  const labelVariant = variant !== 'small' ? 'BODY_MEDIUM_BOLD' : 'BODY_LARGE_BOLD';

  return (
    <Box {...rest}>
      {(label || subtitle) && (
        <Title>
          {label && (
            <Box display="flex" justifyContent="space-between">
              <Label variant={labelVariant} color={inverted ? 'WHITE' : 'BLACK'}>
                <Box display="flex" alignItems="center" component="span">
                  {labelIcon && <Icon name={labelIcon} mr={1} />}
                  <span>{label}</span>
                  {required && <span style={{ color: themeColors.primary[40] }}>&nbsp;*</span>}
                  {tooltip && (
                    <InfoTooltip
                      size={AvailableSizes.SMALL}
                      text={tooltip.helperText}
                      variant={tooltip.variant}
                    />
                  )}
                </Box>
              </Label>
              {info && <Info variant="body2">{info}</Info>}
            </Box>
          )}
          {subtitle && (
            <Note
              variant="subtitle2"
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          )}
        </Title>
      )}
      {children}
    </Box>
  );
};

export default memo(FieldWrapper);
