import { useMutation } from '@apollo/client';
import { Box, DialogActions, DialogContent, styled } from '@mui/material';
import useDatasetMutation from 'components/ComponentsLibrary/hooks/useDatasetMutation';
import { useDocumentUpload } from 'components/DocumentLibrary/hooks';
import {
  datasetImportSchema,
  IDatasetFormValue,
  IDatasetImportFormValues,
} from 'constants/schemas/geoUpload.schema';
import { DialogDefault, InfoTooltip, ThemeButton, ThemeTypography } from 'designSystem';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { DELETE_DOCUMENTS } from 'graphql/mutations';
import omit from 'lodash/omit';
import React, { FC } from 'react';
import { IDataset } from 'types/dataset.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { DocumentCategories } from 'types/document.types';

interface IConfirmDatasetImportDialogProps extends IDefaultDialogProps {
  csvData: string;
  dataset: IDataset;
  newDatasetValues: IDatasetFormValue;
  onProcessingStart: () => void;
  onProcessingComplete: () => void;
}

const FormItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.custom.themeColors.grayScale[5],
  padding: theme.spacing(0, 2),
}));

const StyledTooltip = styled(InfoTooltip)(() => ({
  margin: 0,
}));

const ConfirmDatasetImportDialog: FC<IConfirmDatasetImportDialogProps> = ({
  open,
  csvData,
  dataset,
  newDatasetValues,
  onProcessingComplete,
  onProcessingStart,
  onClose,
}) => {
  const [uploadFile] = useDocumentUpload();
  const [deleteDocument] = useMutation(DELETE_DOCUMENTS);
  const { updateDataset, startDatasetProcessing } = useDatasetMutation();

  const handleSubmit = async (values: IDatasetImportFormValues) => {
    onProcessingStart();
    try {
      // Create a file from the csv data
      const file = new File([csvData], `${newDatasetValues.title}-validated.csv`, {
        type: 'text/csv',
      });

      // Upload the file to the document library
      // @ts-ignore
      const documentCreationResponse = await uploadFile({
        file,
        category: DocumentCategories.GEOGRAPHICAL_FEATURES,
      });

      // Delete old document on the dataset does not
      deleteDocument({ variables: { ids: dataset.documents.map(({ id }) => id) } });

      if (documentCreationResponse?.record.id) {
        // Update the dataset with the new document and eventually with the new values
        const datasetUpdateResponse = await updateDataset(dataset.id, {
          documentIds: [documentCreationResponse.record.id],
          originCountry: newDatasetValues.countryCode,
          ...omit(newDatasetValues, ['ownedBy', 'countryCode']),
        });

        if (datasetUpdateResponse.data?.updateDataset.dataset) {
          // Start the processing of the dataset again to validate the new data
          const processingResponse = await startDatasetProcessing([
            { datasetId: dataset.id, csvSeperator: ';', ...values },
          ]);
          if (processingResponse.data?.processDatasets.datasets.length) {
            // The cache will be updated with the new dataset
            return;
          }
        }
      }
      // eslint-disable-next-line no-console
      console.error('Something went wrong updating / validating the dataset');
      onClose?.();
      onProcessingComplete();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong updating / validating the dataset');
      onClose?.();
      onProcessingComplete();
    }
  };

  return (
    <DialogDefault open={open} title="Confirm Import settings" onClose={onClose}>
      <Formik
        initialValues={datasetImportSchema.default()}
        validationSchema={datasetImportSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <ThemeTypography variant="BODY_MEDIUM_BOLD">
                  Some farms already exist in the platform (same internal id). Do you want to update
                  and override existing farm data with the imported data?
                </ThemeTypography>
                <FormItem>
                  <ThemeTypography variant="BODY_MEDIUM">
                    Import and update farm data for existing farm ids:
                  </ThemeTypography>
                  <Field name="overwriteExisting" fullWidth component={Switch} type="checkbox" />
                  <StyledTooltip
                    variant="INFO"
                    text="Enable this to update the existing Farm data with the new imported Farm data for the Farm ids that already exist in the platform. The old Farm data will be kept as history in the platform and any changes in the imported data will become the new current status for this Farm."
                  />
                </FormItem>

                <ThemeTypography variant="BODY_MEDIUM_BOLD">
                  Do you want farms of this import to be added to an origin cluster?
                </ThemeTypography>
                <FormItem>
                  <ThemeTypography variant="BODY_MEDIUM">
                    Add all imported farms to the same origin cluster:
                  </ThemeTypography>
                  <Field name="clusterSites" fullWidth component={Switch} type="checkbox" />
                  <StyledTooltip
                    variant="INFO"
                    text="Enable this to cluster all farms of each imported dataset to an origin cluster unique per country and partner. Disable this to not cluster farms and store them individually per partner. An origin cluster is a group of sites at the origin that output the same component (e.g. a cluster of cocoa farms)."
                  />
                </FormItem>
              </Box>
            </DialogContent>

            <DialogActions>
              <ThemeButton color="BLUE_ICE" onClick={onClose}>
                Cancel
              </ThemeButton>
              <ThemeButton type="submit" loading={isSubmitting}>
                Confirm
              </ThemeButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default ConfirmDatasetImportDialog;
