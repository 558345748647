import { Box, Checkbox, styled } from '@mui/material';
import { SearchToolbar } from 'components/Forms';
import { Title } from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { FlexBox } from 'components/Structure';
import { CardContainer, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, useMemo, useState } from 'react';
import { IDataset } from 'types/dataset.types';
import { getCountryLabelFromCode } from 'utils/country.utils';

interface IPartnersSelectionProps {
  datasets: IDataset[];
  selectedDatasetIds: string[];
  onSelect: (datasetId: string[]) => void;
}

const DatasetItem = styled(FlexBox)(() => ({
  cursor: 'pointer',
}));

const PartnersSelection: FC<IPartnersSelectionProps> = ({
  datasets,
  selectedDatasetIds,
  onSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredDatasets: IDataset[] = useMemo(() => {
    if (!datasets && !searchTerm?.length) return datasets;

    const filtered = datasets.filter(({ title }) => {
      return title?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filtered;
  }, [datasets, searchTerm]);

  const handleSelect = (companyId: string) => {
    const isItemSelected = selectedDatasetIds.includes(companyId);
    if (!isItemSelected) {
      onSelect([...selectedDatasetIds, companyId]);
    } else {
      const updatedSelection = selectedDatasetIds.filter(partner => partner !== companyId);
      onSelect(updatedSelection);
    }
  };

  const handleSelectAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event.target instanceof HTMLInputElement && event.target.checked) {
      onSelect?.(datasets.map(({ id }) => id));
    } else {
      onSelect?.([]);
    }
  };

  return (
    <Box width="100%">
      <FlexBox mb={0.5}>
        <Checkbox
          checked={selectedDatasetIds.length === datasets.length}
          onClick={handleSelectAll}
        />
        <SearchToolbar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          data-cy="partner-search-input"
          placeholder="Search"
          className="search-input"
        />
      </FlexBox>
      <Box width="100%" display="flex" flexDirection="column" gap={1} mb={0.5}>
        {filteredDatasets.map(({ id, ownedBy: partner, title, originCountry, rawMaterial }) => {
          const isItemSelected = selectedDatasetIds.includes(id);
          return (
            <DatasetItem key={id}>
              <Box>
                <Checkbox checked={isItemSelected} onClick={() => handleSelect(id)} />
              </Box>
              <CardContainer padding={1.5} width="100%" onClick={() => handleSelect(id)}>
                <FlexBox justifyContent="space-between">
                  <FlexBox>
                    <Icon name="polygon-data" color="gray-100" size="xxx-large" mr={2} />
                    <Box display="flex" flexDirection="column">
                      <Title>{title}</Title>
                      <FlexBox>
                        <FlexBox mr={1}>
                          <Icon name="raw-material" color="gray-80" size="small" mr={0.5} />
                          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                            {rawMaterial.title}
                          </ThemeTypography>
                        </FlexBox>
                        <FlexBox mr={1}>
                          <Icon name="location" color="gray-80" size="small" mr={0.5} />
                          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                            {getCountryLabelFromCode(originCountry)}
                          </ThemeTypography>
                        </FlexBox>
                        <FlexBox>
                          <Icon name="partner" color="gray-80" size="small" mr={0.5} />
                          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                            {partner.name}
                          </ThemeTypography>
                        </FlexBox>
                      </FlexBox>
                    </Box>
                  </FlexBox>
                </FlexBox>
              </CardContainer>
            </DatasetItem>
          );
        })}
      </Box>
    </Box>
  );
};

export default PartnersSelection;
