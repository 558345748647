import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { AlertBanner, DropDown } from 'components/Forms';
import { useDialog } from 'components/hooks';
import { InfoTooltip, ThemeButton } from 'designSystem';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { IChainStep } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableSizes } from 'types/enums';
import { CHAIN_STEPS_OPTIONS, TChainStepOption } from '../../ChainMapping/constants/steps';
import { Trash } from '@styled-icons/bootstrap';
import { FlexBox } from 'components/Structure';
import { StepIndexNumber } from '../Forms/styles';

interface IEditStepDialogProps extends IDefaultDialogProps {
  steps: IChainStep[];
  editStepId: string;
  /** If the step has components it can not be deleted and if it contains activities we request to confirm the deletion */
  isDeletable: boolean;
  hasActivities: boolean;
  onDeleteStep: (stepId: string) => void;
  onEditStep: (step: IChainStep) => void;
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.custom.colors.actionButtonHover,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(4),
  minWidth: '500px',

  '& .MuiPaper-root:not(.MuiAlert-root)': {
    minWidth: '500px',
  },
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledDialogContent = styled(DialogContent)({
  overflow: 'hidden',
});

const EditStepDialog: FC<IEditStepDialogProps> = ({
  open,
  steps,
  editStepId,
  hasActivities,
  isDeletable,
  onDeleteStep,
  onEditStep,
  onClose,
}) => {
  const { openDialog } = useDialog();

  const [currentSteps, setCurrentSteps] = useState<IChainStep[]>(steps);
  const [hasOrderError, setOrderError] = useState<boolean>(false);

  const editStepIndex = useMemo(
    () => currentSteps.findIndex(({ id }) => id === editStepId),
    [currentSteps, editStepId]
  );

  // Workaround until we remove the old custom step names
  const selectedOption = (
    currentSteps[editStepIndex]?.title === ''
      ? currentSteps[editStepIndex]?.title
      : CHAIN_STEPS_OPTIONS.find(
          step => step.toLowerCase() === currentSteps[editStepIndex]?.title.toLowerCase()
        ) || 'Other'
  ) as TChainStepOption;
  // If the selected option is 'Other', we have to add 'Other' option to the list of options otherwise its not selectable
  const options = (
    selectedOption === 'Other' ? [...CHAIN_STEPS_OPTIONS, 'Other'] : CHAIN_STEPS_OPTIONS
  ) as TChainStepOption[];

  const validateOrder = () => {
    const currentStepNames = currentSteps.map(({ title }) => title);
    // We do not care about the 'Other' step (can have every position)
    const filteredKey = currentStepNames.filter(
      key => key !== 'Other' && CHAIN_STEPS_OPTIONS.includes(key)
    );
    // Create an index mapping to check if the order is correct
    const indexKeyMapping = filteredKey.map(key => CHAIN_STEPS_OPTIONS.indexOf(key));
    // Checking if the index of the current key is always smaller or equal than the next key
    const isValidOrder = indexKeyMapping.every(
      (keyIndex, index, array) => index === 0 || array[index - 1] <= keyIndex
    );
    setOrderError(!isValidOrder);
    return isValidOrder;
  };

  useEffect(() => {
    validateOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSteps]);

  const onSaveChange = () => {
    if (hasOrderError) {
      return;
    }
    onEditStep(currentSteps[editStepIndex]);
    onClose?.();
  };

  const handleValueChange = (newValue: TChainStepOption) => {
    setCurrentSteps(items =>
      items.map(item =>
        item.id === editStepId
          ? { id: item.id, title: newValue, isDeletable: item.isDeletable }
          : item
      )
    );
  };

  const handleDeleteStep = () => {
    onDeleteStep(editStepId);
    onClose?.();
  };

  const handleDeleteClick = () => {
    if (!isDeletable) {
      return;
    }
    if (hasActivities) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Delete step',
          submitText: 'Delete',
          itemTitle: `${editStepIndex}. ${currentSteps[editStepIndex]?.title}`,
          text: `Are you sure you want to delete this step? All component chain activities contained in this step will be lost and you will not be able to undo this action.`,
          onSubmit: handleDeleteStep,
          onCancel: () => undefined,
        },
      });
    } else {
      handleDeleteStep();
    }
  };

  return (
    <StyledDialog open={!!open} onClose={onClose} data-cy="edit-step-dialog">
      <CloseButton onClick={onClose} data-cy="close-step-dialog-btn">
        <Close size={23} />
      </CloseButton>
      <DialogTitle>
        <FlexBox>
          Edit step
          <InfoTooltip
            size={AvailableSizes.SMALL}
            text="Edit the supply chain step type or delete step completely."
          />
        </FlexBox>
      </DialogTitle>
      <StyledDialogContent>
        <FlexBox mt={1}>
          <StepIndexNumber>{editStepIndex + 1}</StepIndexNumber>
          <Box mr={2} />
          <DropDown<TChainStepOption>
            fullWidth
            data-testId="chain-step-dropdown"
            size={AvailableSizes.SMALL}
            variant="outlined"
            currentValue={selectedOption}
            placeholder="Select activity category"
            options={options}
            onChange={handleValueChange}
            renderItems={title => title}
          />
        </FlexBox>

        {hasOrderError && (
          <Box mt={2}>
            <AlertBanner severity="warning">
              Please check the order of the step types. ‘Origin’ always has to be the first step,
              followed by ‘Processing’, ’Handling’, ‘Manufacturing’, ’Distribution’, and ‘Return’,
              in that order. You can create as many of each step type as you like.
            </AlertBanner>
          </Box>
        )}
      </StyledDialogContent>

      <StyledDialogActions>
        <ThemeButton
          size="large"
          color="BLUE_ICE"
          data-cy="delete-step-button"
          disabled={!isDeletable}
          startIcon={<Trash size={12} />}
          onClick={handleDeleteClick}
        >
          Delete step
        </ThemeButton>
        <ThemeButton
          size="large"
          color="YELLOW"
          data-cy="edit-step-submit-button"
          disabled={hasOrderError}
          onClick={onSaveChange}
        >
          Apply changes
        </ThemeButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default EditStepDialog;
