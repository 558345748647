import { FC, PropsWithChildren } from 'react';
import { IRawMaterial } from './component.types';
import { IDataset } from './dataset.types';
import { PartnerRequest } from './partner.types';
import { ICultivatedAreas } from './site.types';

export enum CsvSeparator {
  'COMMA' = 'COMMA',
  'SEMICOLON' = 'SEMICOLON',
  'TAB' = 'TAB',
  'AUTO_DETECT' = 'AUTO_DETECT',
}

export enum DecimalSeparator {
  'DOT' = 'DOT',
  'COMMA' = 'COMMA',
}

export type CsvSeparatorType = `${CsvSeparator}`;

export type DecimalSeparatorType = `${DecimalSeparator}`;

export enum EudrStatementStatus {
  CREATED = 'CREATED',
  EVALUATING_AFFECTEDNESS = 'EVALUATING_AFFECTEDNESS',
  COLLECTING_GEO_DATA = 'COLLECTING_GEO_DATA',
  SELECTING_RISK_ANALYSIS = 'SELECTING_RISK_ANALYSIS',
  PENDING_RISK_ANALYSIS = 'PENDING_RISK_ANALYSIS',
  COMPLETED_RISK_ANALYSIS = 'COMPLETED_RISK_ANALYSIS',
  SELECTING_ASSESSMENTS = 'SELECTING_ASSESSMENTS',
  PENDING_ASSESSMENTS = 'PENDING_ASSESSMENTS',
  COMPLETED_ASSESSMENTS = 'COMPLETED_ASSESSMENTS',
  RISK_MITIGATION = 'RISK_MITIGATION',
  COMPLETED = 'COMPLETED',
  SUBMITTED_EU = 'SUBMITTED_EU',
  ARCHIVED = 'ARCHIVED',
}

export type EudrStatementStatusType = `${EudrStatementStatus}`;

export enum EudrResponsibilityType {
  OPERATOR = 'OPERATOR',
  TRADER = 'TRADER',
  TRADER_OPERATOR = 'TRADER_OPERATOR',
  PARTNER = 'PARTNER',
}

export enum EudrAffectednessType {
  NOT_AFFECTED = 'NOT_AFFECTED',
  PARTIALLY_AFFECTED = 'PARTIALLY_AFFECTED',
  INDIRECTLY_AFFECTED = 'INDIRECTLY_AFFECTED',
  FULLY_AFFECTED = 'FULLY_AFFECTED',
}

export enum RiskLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type RiskLevelType = `${RiskLevel}`;

export interface EudrStatement {
  id: string;
  title: string;
  createdTimestamp: Date;
  modifiedTimestamp: Date;
  year: number;
  companyResponsibilityType: EudrResponsibilityType;
  companyAffectednessType: EudrAffectednessType;
  involvedPartnerCompanies: { id: string }[];
  riskStatus: RiskLevelType;
  status: EudrStatementStatusType;
  datasets: IDataset[];
  aggregatedQuestionnaires?: {
    aggregatedResponseSummary: { answered: number; notAnswered: number; total: number };
    aggregatedRiskSummary: {
      lowRiskPercentage: number;
      mediumRiskPercentage: number;
      highRiskPercentage: number;
    };
    requests: PartnerRequest[];
  };
}

export enum ChangeDetection {
  LOW = 'No/Negligible risk',
  MEDIUM = 'Medium risk',
  HIGH = 'High risk',
}

export interface IComplianceGuideSubSection {
  key: string;
  title: string;
  itemTitle?: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: FC<PropsWithChildren & any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentProps?: any;
}
export interface IComplianceGuideSection {
  key: string;
  title: string;
  subSections: IComplianceGuideSubSection[];
}

export interface IComplianceGuideDetails {
  key: string;
  image: string;
  title: string;
  illustration: string;
  regulationUrl: string;
}

export type IComplianceGuide = IComplianceGuideDetails & { sections: IComplianceGuideSection[] };

export interface GeoCParcel {
  geocParcelId: string;
  geocBatchId: string;
  harvestDate: string;
  rawMaterialId: string;
  cultivatedAreas: ICultivatedAreas;
  resultTimestamp: string;
  deforestationRisk: RiskLevelType;
  baselineDate: string;
  landcoverPngBaselineDate: string;
  forestCoverage: number;
  plantationCoverage: number;
  shrubsCoverage: number;
  noTreesCoverage: number;
  rgbPngBaselineDate: string;
  rgbPngProductionEnddate: string;
  forestChangePng: string;
  deforestationIndex: number;
  deforestationAreaHa: number;
  degradationRisk: RiskLevelType;
  degradationAreaHa: number;
  degradationIndex: number;
  countryRisk: RiskLevelType;
  geocBatch: GeoCBatch;
  rawMaterial: IRawMaterial;
  id: string;
  createdTimestamp: Date;
}

export interface GeoCBatch {
  id: string;
  createdTimestamp: Date;
  externalBatchId: string;
  geocBatchId: string;
  datasetId: string;
  weight: number;
  harvestDate: string;
  riskStatus: RiskLevelType;
}

export interface GeocParcelConnection {
  edges: { node: GeoCParcel }[];
  highRisks: number;
  mediumRisks: number;
  lowRisks: number;
  count: number;
}

export interface QuestionnaireResponsesOption {
  id: string;
  text: string;
  weight: number;
  riskLevel: RiskLevel;
  answerPercentage: number;
}

export interface QuestionnaireResponse {
  text: string;
  options: QuestionnaireResponsesOption[];
}
export interface QuestionnaireResponseSummary {
  averageResponseTime: number;
  riskMode: RiskLevel;
}

export interface FileConfiguration {
  csvSeparator?: CsvSeparatorType;
  decimalSeparator?: DecimalSeparatorType;
}
