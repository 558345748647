import React, { FC } from 'react';
import { Box } from '@mui/material';
import { IDataset, DatasetStatus } from 'types/dataset.types';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { DetailsCard, Badge } from 'components/DataImport/DataImport.styles';
import Moment from 'react-moment';

interface IDatasetDetailsCardProps {
  dataset: IDataset;
  buttonText?: string;
  onClickDataset: (datasetId: string) => void;
  onDeleteDataset: (dataset: IDataset) => void;
}

const DatasetDetailsCard: FC<IDatasetDetailsCardProps> = ({
  dataset,
  buttonText = 'View',
  onClickDataset,
  onDeleteDataset,
}) => {
  return (
    <DetailsCard>
      <PageSubTitle title="Details" />
      <Box mb={1} />
      <ThemeTypography variant="BODY_MEDIUM_BOLD">{dataset.title || 'Unnamed'}</ThemeTypography>
      {dataset.status === DatasetStatus.VERIFICATION_FAILED && (
        <Box my={1}>
          <Badge severity="warning">
            {dataset.issues.length} warning
            {dataset.issues.length && dataset.issues.length > 1 && 's'}
          </Badge>
        </Box>
      )}
      <FlexBox mb={2}>
        <ThemeTypography variant="BODY_MEDIUM">
          Created <Moment format="LL">{dataset.createdTimestamp}</Moment>
        </ThemeTypography>
      </FlexBox>
      <FlexBox>
        <ThemeButton
          color="BLUE_ICE"
          startIcon={<Icon name="show" />}
          onClick={() => onClickDataset(dataset.id)}
        >
          {buttonText}
        </ThemeButton>
        <Box ml={2} />
        <ThemeButton
          color="BLUE_ICE"
          startIcon={<Icon name="delete" />}
          onClick={() => onDeleteDataset(dataset)}
        >
          Delete
        </ThemeButton>
      </FlexBox>
    </DetailsCard>
  );
};

export default DatasetDetailsCard;
