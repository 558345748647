import { Box, styled, ButtonGroup } from '@mui/material';
import FarmImport from 'components/DataImport/Views/FarmImport';
import GeoDataImport from 'components/DataImport/Views/GeoDataImport';
import { DATA_IMPORT_CATEGORY_CONFIG } from 'components/DataImport/constants/dataImportConfig';
import { PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { InfoTooltip, Tabs, ThemeButton } from 'designSystem';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AvailableSizes } from 'types/enums';

const Container = styled('div')(() => ({
  position: 'relative',
  minHeight: 50,
}));

const DataImports: FC = () => {
  usePageTitle('Origin data import');
  const { id: companyId } = useParams<{ id?: string }>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  // Farm tab is the default tab
  const categoryConfig = DATA_IMPORT_CATEGORY_CONFIG[0];

  // Nothing, since only one tab exists for now
  const handleTabChange = () => undefined;

  return (
    <PageContainer>
      <PageTitle
        title="Origin data import"
        goBackUrl={companyId ? '/partners' : undefined}
        goBackLabel={companyId ? 'All partners' : undefined}
      />

      <Container>
        <Box mt={3} mb={2.5}>
          <Tabs
            tabs={DATA_IMPORT_CATEGORY_CONFIG.map(({ tab }) => tab)}
            selectedTab={categoryConfig.key}
            onChange={handleTabChange}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1} mb={1.5}>
          <ButtonGroup>
            <ThemeButton
              className={selectedTab === 0 ? 'selected' : ''}
              onClick={() => setSelectedTab(0)}
            >
              Advanced geo-data import
            </ThemeButton>
            <ThemeButton
              className={selectedTab === 1 ? 'selected' : ''}
              onClick={() => setSelectedTab(1)}
            >
              Farm data import
            </ThemeButton>
          </ButtonGroup>
          <InfoTooltip
            size={AvailableSizes.SMALL}
            text="Upload farm data by file upload or by manually adding information. For more flexibility and validation in uploading farm geo-data (supporting .geojson, .kml, .csv, or .xlsx files), use the advanced geo-data import. "
          />
        </Box>
      </Container>

      {selectedTab === 0 && <GeoDataImport />}
      {selectedTab === 1 && <FarmImport />}
    </PageContainer>
  );
};

export default DataImports;
