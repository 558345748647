import { Box, ButtonGroup, styled } from '@mui/material';
import PartnerAutocompleteWrapper from 'components/ChainMapping/Forms/PartnerAutocomplete';
import UploadField from 'components/ComplianceGuide/components/Forms/UploadField';
import RawMaterialSelection from 'components/ComponentsLibrary/Forms/RawMaterialSelection';
import { TextInputField } from 'components/Forms';
import CountrySelectField from 'components/Forms/CountrySelectField';
import { useConfig } from 'components/hooks';
import { IGeoUploadFormValue } from 'constants/schemas/geoUpload.schema';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, useField } from 'formik';
import React, { FC } from 'react';
import { FileConfiguration } from 'types/compliance.types';

interface IDatasetUploadFormProps {
  datasetIndex: number;
  disableOwnerSelection?: boolean;
}

const SelectContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.grayScale[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '& > div': {
    flex: 3,
  },
}));

const UploadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '& > div': {
    flex: 2,
  },
}));

const HintContainer = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const DatasetUploadForm: FC<IDatasetUploadFormProps> = ({
  datasetIndex,
  disableOwnerSelection,
}) => {
  const [{ value: datasetValues }, , { setValue }] = useField<IGeoUploadFormValue | undefined>(
    `[${datasetIndex}]`
  );
  const { bookGeoDataSupportUrl } = useConfig();

  const handleMultiplePlotsPerFileChange = (allowMultipleFiles: boolean) => {
    if (!datasetValues) {
      console.error('Could not get current selected dataset to change multiple plots per file');
      return;
    }
    setValue({
      ...datasetValues,
      allowMultipleFiles,
    });
  };

  const handleFileConfigurationChange = (fileConfiguration: FileConfiguration) => {
    if (!datasetValues) {
      console.error('Dataset values are not set');
      return;
    }
    setValue({
      ...datasetValues,
      fileConfiguration: {
        csvSeparator:
          fileConfiguration.csvSeparator || datasetValues.fileConfiguration.csvSeparator,
        decimalSeparator:
          fileConfiguration.decimalSeparator || datasetValues.fileConfiguration.decimalSeparator,
      },
    });
  };

  return (
    <>
      <Box my={2}>
        <ButtonGroup>
          <ThemeButton
            className={datasetValues?.allowMultipleFiles ? '' : 'selected'}
            onClick={() => handleMultiplePlotsPerFileChange(false)}
          >
            Multiple plots per file
          </ThemeButton>
          <ThemeButton
            className={datasetValues?.allowMultipleFiles ? 'selected' : ''}
            onClick={() => handleMultiplePlotsPerFileChange(true)}
          >
            One file per plot
          </ThemeButton>
        </ButtonGroup>
      </Box>

      {/* Dataset content */}
      <SelectContainer>
        <TextInputField
          name={`[${datasetIndex}].title`}
          isRequired
          helperText="Add a name to your geo-dataset that helps you and your colleagues remember to what origin this dataset refers to. Using specific region names could be helpful."
          label="Title"
          size="small"
          placeholder='E.g. "Origin, geo-data Ghana"'
        />
        <RawMaterialSelection
          isRequired
          multiple={false}
          name={`[${datasetIndex}].rawMaterialId`}
          size="small"
          helperText="Select the commodity that is sourced from this origin."
        />
        <CountrySelectField
          isRequired
          name={`[${datasetIndex}].countryCode`}
          label="Country of origin"
          helperText="Select the country where this origin geo-data is located. You can only have one country per origin geo-dataset."
        />
        <PartnerAutocompleteWrapper
          isRequired
          name={`[${datasetIndex}].ownedBy`}
          label="Owner"
          disabled={disableOwnerSelection}
          helperText="Select an owner of this origin geo-dataset. This would optimally be a supplier that is involved in the supply chain connected to this origin. You can also select your own company."
        />
      </SelectContainer>

      <UploadContainer>
        <Field
          component={UploadField}
          name={`[${datasetIndex}].files`}
          multiple={datasetValues?.allowMultipleFiles}
          supportedFileTypes={['xlsx', 'csv', 'kml', 'geojson']}
          fileConfiguration={datasetValues?.fileConfiguration}
          onFileConfigurationChange={handleFileConfigurationChange}
        />

        <Box display="flex" flexDirection="column" gap={2}>
          <HintContainer>
            <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
              For a farm plot to form a polygon, your geo-data upload needs to include multiple
              point coordinates. A single coordinate for one farm plot is also accepted - this would
              create point instead of a polygon . You can either upload multiple files for one
              dataset (one file per plot) or one file for one dataset containing multiple plots.
            </ThemeTypography>
          </HintContainer>

          <HintContainer>
            <ThemeTypography variant="BODY_MEDIUM" color="BLUE_80">
              If you do not have the geo-data in any of the supported file formats, we can also
              allocate resources to help you to manually convert the data to a readable format for
              the deforestation analysis. Set up a meeting with us to discuss this further.
            </ThemeTypography>
            <Box mt={2} />
            <ThemeButton
              color="WHITE"
              startIcon={<Icon name="calendar" />}
              onClick={() => window.open(bookGeoDataSupportUrl, '_blank')}
            >
              Schedule meeting
            </ThemeButton>
          </HintContainer>
        </Box>
      </UploadContainer>
    </>
  );
};

export default DatasetUploadForm;
