import { MultiPolygon } from 'types/map.types';
import {
  Feature,
  FeatureCollection,
  Geometry,
  Polygon as TurfPolygon,
  area,
  polygon,
} from '@turf/turf';
import { v4 as uuid } from 'uuid';
import round from 'lodash/round';

export const transformLatLngToArrayPosition = (polygon: { lat: number; lng: number }[]) =>
  polygon.map(c => [c.lng, c.lat]);

/* Correct format for mapbox */
export const transformLatLngArrayToLngLat = (multiPolygon: MultiPolygon): MultiPolygon =>
  multiPolygon.map(polygon => polygon.map(([lat, lng]) => [lng, lat]));

/* Warning: This format cna not be used for mapbox and is only shown in the json formatter */
export const transformLngLatArrayToLatLng = (multiPolygon: MultiPolygon): MultiPolygon =>
  multiPolygon.map(polygon => polygon.map(([lng, lat]) => [lat, lng]));

/** Assigns a random uuid to the feature set */
export const transformMultiPolygonToFeature = (
  multiPolygon: MultiPolygon
): Feature<TurfPolygon>[] =>
  multiPolygon.map(singlePolygon => polygon([singlePolygon], {}, { id: uuid() }));

/**
 * Returns the calculated size in square km rounded to 3 decimal digits
 * To calculate for the slight difference of the earth radius of the turf area function, we multiply by 0.993699583 to match the backends calculation
 */
export const calculateArea = (feature: Feature<unknown> | FeatureCollection<unknown> | Geometry) =>
  round((area(feature) * 0.993699583) / 1_000_000, 3);
