import { Typography } from '@mui/material';
import { CloudUpload } from '@styled-icons/bootstrap/CloudUpload';
import { FileEarmarkText } from '@styled-icons/bootstrap/FileEarmarkText';
import { AllowedFileType } from 'components/Structure';
import React, { FC } from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Booleanish, booleanish } from 'types/booleanish.types';

export enum SupportedFileTypes {
  PDF = 'pdf',
  CSV = 'csv',
  XLS = 'xls',
  XLSX = 'xlsx',
  KML = 'kml',
  XML = 'xml',
  JSON = 'json',
  GEO_JSON = 'geojson',
  IMAGE = 'image',
  VIDEO = 'video',
  TXT = 'txt',
}

export interface IDropzoneProps {
  maxFileMb?: number;
  multiple?: boolean;
  disabled?: boolean;
  maxFiles?: number;
  supportedFileTypes?: `${SupportedFileTypes}`[];
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
}

export const FILE_MIME_TYPES: Record<SupportedFileTypes, Accept> = {
  [SupportedFileTypes.PDF]: { 'application/pdf': ['.pdf'] },
  [SupportedFileTypes.CSV]: { 'text/csv': ['.csv'] },
  [SupportedFileTypes.XLS]: { 'application/vnd.ms-excel': ['.xls'] },
  [SupportedFileTypes.XLSX]: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  },
  [SupportedFileTypes.KML]: { 'application/vnd.google-earth.kml+xml': ['.kml'] },
  [SupportedFileTypes.XML]: { 'application/xml': ['.xml'] },
  [SupportedFileTypes.JSON]: { 'application/json': ['.json'] },
  [SupportedFileTypes.GEO_JSON]: { 'application/geo+json': ['.geojson'] },
  [SupportedFileTypes.IMAGE]: {
    'image/*': ['.png', '.jpg', '.jpeg', '.svg', '.gif', '.webp', '.heic'],
  },
  [SupportedFileTypes.VIDEO]: { 'video/*': ['.mp4', '.mpeg', '.ogv'] },
  [SupportedFileTypes.TXT]: { 'text/plain': ['.txt'] },
};

export const DropContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(4),
  backgroundColor: '#fff',
  marginTop: theme.spacing(4),

  '& .dropzone': {
    flex: 1,
    display: 'flex',
    height: '130px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },

  '&:hover': {
    cursor: 'pointer',
  },
}));

const IconContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(2),
}));

const UploadContainer = styled('div')<{ 'is-drag-active': booleanish }>(
  ({ theme, 'is-drag-active': isDragActive }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    borderWidth: 2,
    borderColor: theme.custom.themeColors.grayScale[30],
    borderStyle: 'dashed',
    textAlign: 'center',
    transition: 'all 0.125s ease',
    cursor: 'pointer',
    height: '250px',

    '& *': {
      color: theme.palette.grey[500],
      fill: theme.palette.grey[500],
    },

    '&:hover': {
      borderColor: theme.custom.colors.blueFrost,

      '& *': {
        color: theme.custom.colors.blueFrost,
        fill: theme.custom.colors.blueFrost,
      },
    },
  })
);

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Dropzone: FC<IDropzoneProps> = ({
  maxFileMb = 2,
  supportedFileTypes = ['pdf'],
  multiple = true,
  disabled = false,
  maxFiles = 500,
  onDrop,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    onDrop,
    multiple,
    maxFiles,
    accept: supportedFileTypes.reduce((prev, type) => ({ ...prev, ...FILE_MIME_TYPES[type] }), {}),
  });

  return (
    <DropContainer>
      <div
        {...getRootProps({
          className: 'dropzone',
        })}
      >
        <input {...getInputProps()} data-testid="dropzone-input" />
        <UploadContainer is-drag-active={Booleanish(isDragActive)} data-cy="pdf-dropzone">
          <IconContainer>
            <CloudUpload size={40} />
          </IconContainer>
          <Description color="inherit" variant="subtitle2">
            Click or drag and drop to upload new document{multiple ? 's' : ''}
          </Description>
          <AllowedFileType
            icon={<FileEarmarkText size={20} />}
            description={`${supportedFileTypes.reduce(
              (prev, type) =>
                `${prev} ${Object.values(FILE_MIME_TYPES[type]).reduce(
                  (prev, next) => `${prev} ${next}`,
                  ''
                )}`,
              ''
            )}
             up to ${maxFileMb}MB`}
            title={undefined}
          />
        </UploadContainer>
      </div>
    </DropContainer>
  );
};

export default Dropzone;
