import { useUploadState, useLogEvent } from 'components/hooks';
import partition from 'lodash/partition';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import { useApolloClient } from '@apollo/client';

function useDocumentUploadActions() {
  const { logEvent } = useLogEvent();
  const { addFiles } = useUploadState();
  const client = useApolloClient();
  const MAX_MB = 25;

  const checkFileSize = ({ size }) => {
    return size < MAX_MB * 1000000;
  };

  const onDrop = (acceptedFiles, fileRejections, addionalProperties) => {
    logEvent('UPLOAD_DOCUMENT');
    const [checkedAcceptedFiles, rejectedAcceptedFiles] = partition(acceptedFiles, checkFileSize);

    const rejectedFiles = fileRejections.map(({ file }) => ({
      error: 'Invalid file type',
      file,
    }));

    const rejected = rejectedAcceptedFiles.map(file => ({
      error: 'File size too large',
      file,
    }));

    const finalAcceptedFiles = checkedAcceptedFiles.map(file => ({
      file,
      itemType: 'document',
    }));

    addFiles(finalAcceptedFiles, [...rejectedFiles, ...rejected], addionalProperties);
  };

  const getSelectedDocuments = selectedIds => {
    return selectedIds.map(id =>
      client.readFragment({
        fragment: DOCUMENT_FRAGMENT,
        id: `Document:${id}`,
      })
    );
  };

  return {
    onDrop,
    getSelectedDocuments,
    maxSize: MAX_MB,
  };
}

export default useDocumentUploadActions;
