import React, { FC, useEffect, useMemo, useState } from 'react';
import { DialogDefault, ThemeButton } from 'designSystem';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Box, styled } from '@mui/material';
import {
  FormContainerLeft,
  FormContainerRight,
  BlockContainer,
} from 'components/ChainMapping/Forms/styles';
import { DialogInner } from 'components/Partners/Partners.styles';
import { IBasePartnerCompany } from 'types/partner.types';
import { SiteOwnership, ISite, IClusterSite, SiteType, SiteViewOption } from 'types/site.types';
import SelectSiteType from 'components/Sites/SiteSelectionDialog/SelectSiteType';
import SiteSelection from './SiteSelection';
import useSites from 'hooks/useSites';
import useSiteMutations from '../hooks/useSiteMutations';
import ClusterSummary from './ClusterSummary';
import { useDialog } from 'components/hooks';

interface ISelectSitesDialogProps extends IDefaultDialogProps {
  siteCluster: IClusterSite;
  partner?: IBasePartnerCompany;
}

const StyledBlockContainer = styled(BlockContainer)({
  paddingTop: 0,
  height: '100%',
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(4),
  boxShadow: theme.custom.shadows[4],
  backgroundColor: 'white',
}));

const SelectSitesDialog: FC<ISelectSitesDialogProps> = ({ onClose, open, siteCluster }) => {
  const [selectedSites, setSelectedSites] = useState<ISite[]>([]);
  const [currentOwnershipType, setCurrentOwnershipType] = useState<SiteOwnership>(
    SiteOwnership.EXTERNAL
  );
  const [selectedPartner, setSelectedPartner] = useState<IBasePartnerCompany | undefined>();

  const siteType = useMemo(() => selectedSites[0]?.siteType, [selectedSites]);
  const rawMaterial = useMemo(
    () =>
      selectedSites[0]?.siteType === SiteType.FARM ? selectedSites[0]?.rawMaterial : undefined,
    [selectedSites]
  );

  const disableAddButton = useMemo(() => {
    // Enable button if no sites are selected
    if (selectedSites.length === 0) return false;

    if (!siteType) return true;

    // Check if all sites have the same siteType
    const allSameSiteType = selectedSites?.every(site => site.siteType === siteType);

    // Check if all farm sites have the same raw material
    const allSameRawMaterial = selectedSites.every(
      site =>
        site.siteType !== SiteType.FARM ||
        (site.siteType === SiteType.FARM && site?.rawMaterial?.id === rawMaterial?.id)
    );

    // Check if all sites are in the same country
    const allSameCountry = selectedSites.every(
      site => site.countryCode === siteCluster.countryCode
    );

    return !allSameSiteType || !allSameRawMaterial || !allSameCountry;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSites, siteType, rawMaterial]);

  const { updateSite, loading: loadingMutation } = useSiteMutations();
  const { closeDialog: onCloseAll } = useDialog();

  const { sites, loading, hasNextPage, searchTerm, handlePageEndReached, handleSearchTermChange } =
    useSites({
      siteOwnership: currentOwnershipType,
      skipCache: true,
      companyId: selectedPartner?.id,
      skipQuery: currentOwnershipType === SiteOwnership.EXTERNAL && !selectedPartner?.id,
      skipUseQueryParamSearch: true,
      siteViewOption: SiteViewOption.LIST,
      fetchedSiteTyped: [
        SiteType.FARM,
        SiteType.COLLECTION_SITE,
        SiteType.FOREST,
        SiteType.GENERIC_SITE,
        SiteType.MINE,
        SiteType.ORIGIN_SITE,
      ],
    });

  const handleAddSites = async () => {
    const clusterInput = {
      title: siteCluster.title,
      locationCoordinates: siteCluster.locationCoordinates,
      externalId: siteCluster.externalId,
      ownedById: siteCluster.ownedBy?.id,
      image: siteCluster.image?.url
        ? {
            imageId: siteCluster.image?.id,
            crop: siteCluster.image?.crop,
          }
        : undefined,
      description: siteCluster.description,
    };

    await updateSite({
      variables: {
        id: siteCluster.id,
        input: {
          ...clusterInput,
          siteType: SiteType.CLUSTER,
          siteClusterInput: {
            siteIds: selectedSites.map(site => site.id),
          },
        },
      },
    });
    onCloseAll?.();
  };

  useEffect(() => {
    // select sites that are already in the cluster
    if (siteCluster.sites?.edges.length) {
      setSelectedSites(siteCluster.sites.edges.map(edge => edge.node));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogDefault
      title="Manage sites in cluster"
      onClose={onClose}
      open={open}
      fullWidth
      iconName="site-cluster-manage"
      maxWidth="xl"
      background="#fff"
    >
      <DialogInner container minHeight={620}>
        <FormContainerLeft item xs={6}>
          <StyledBlockContainer container z-index={1}>
            <SelectSiteType
              currentOwnershipType={currentOwnershipType}
              selectedPartner={selectedPartner}
              onCurrentOwnershipTypeChange={setCurrentOwnershipType}
              onSelectedPartnerChange={setSelectedPartner}
            />
          </StyledBlockContainer>
        </FormContainerLeft>
        <FormContainerRight item xs={6}>
          <StyledBlockContainer container z-index={0}>
            <ClusterSummary
              siteCluster={siteCluster}
              rawMaterialTitle={rawMaterial?.title}
              siteType={siteType}
              siteCount={selectedSites.length}
            />
            <SiteSelection
              sites={sites}
              selectedSites={selectedSites}
              currentOwnershipType={currentOwnershipType}
              hasNextPage={hasNextPage}
              loading={loading}
              searchTerm={searchTerm}
              onSearchTermChange={handleSearchTermChange}
              onSelectionChange={setSelectedSites}
              onPageEndReached={handlePageEndReached}
            />
          </StyledBlockContainer>
          <ButtonContainer>
            <Box display="flex" justifyContent="end" gap={1.8} width="100%">
              <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={onClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                color="YELLOW"
                size="large"
                type="submit"
                data-cy="add-sites-button"
                disabled={disableAddButton}
                tooltip={
                  disableAddButton ? 'Please select sites of the same type and country' : undefined
                }
                loading={loadingMutation}
                onClick={handleAddSites}
              >
                Save selection
              </ThemeButton>
            </Box>
          </ButtonContainer>
        </FormContainerRight>
      </DialogInner>
    </DialogDefault>
  );
};

export default SelectSitesDialog;
