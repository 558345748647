import { ApolloError, useQuery } from '@apollo/client';
import { useLogEvent } from 'components/hooks';
import useConfig from 'components/hooks/useConfig';
import useDialog from 'components/hooks/useDialog';
import useFeatureFlags, { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { GET_ALL_PARTNER_COMPANIES } from 'graphql/queries/partner.queries';
import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import { IBasePartnerCompany } from 'types/partner.types';
import { GraphQlConnection } from 'types/types';
import { BooleanParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { PartnerHeaderColumnEnum } from '../PartnersTable/partnerHeaderColumns';

interface UsePartnersData {
  partners: IBasePartnerCompany[];
  searchTerm: string | null;
  partnerToDelete: IBasePartnerCompany | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  hasNextPage: boolean;
  handlePageEndReached: () => void;
  handleCreatePartner: () => void;
  handleSearchTermChange: (searchTerm: string) => void;
  clearDeletingPartner: () => void;
  onDeletePartner: (partner: IBasePartnerCompany) => void;
  onEditPartner: (partner: IBasePartnerCompany, lang?: string) => void;
  refetch: () => void;
}

interface Props {
  customSearch?: string;
  useInfiniteScroll?: boolean;
  useSearchQuery?: boolean;
  skip?: boolean;
}

const usePartners = ({
  customSearch,
  useInfiniteScroll,
  useSearchQuery,
  skip = false,
}: Props = {}): UsePartnersData => {
  const { appQueryParams } = useConfig();

  const [partnerToDelete, setPartnerToDelete] = useState<IBasePartnerCompany>();

  const [query, setQuery] = useQueryParams(
    {
      [appQueryParams.new]: withDefault(BooleanParam, false),
      [appQueryParams.query]: withDefault(StringParam, ''),
      [appQueryParams.order]: withDefault(StringParam, 'DESC'),
      [appQueryParams.orderBy]: withDefault(
        StringParam,
        PartnerHeaderColumnEnum.MODIFIED_TIMESTAMP
      ),
    },
    { removeDefaultsFromUrl: true }
  );

  const addNewPartner = query[appQueryParams.new];
  const searchTerm = (query[appQueryParams.query] as string) || '';

  const orderByMethod = query[appQueryParams.order] as 'ASC' | 'DESC' | undefined;
  const orderByField = query[appQueryParams.orderBy];

  const { isFeatureEnabled } = useFeatureFlags();
  const canInvitePartners = isFeatureEnabled(FeatureFlag.PARTNER_INVITATIONS);
  const { logEvent } = useLogEvent();
  const { openDialog } = useDialog();

  const { data, loading, error, refetch, fetchMore } = useQuery<{
    partners: GraphQlConnection<IBasePartnerCompany>;
  }>(GET_ALL_PARTNER_COMPANIES, {
    variables: {
      filters: {
        searchTerm: useSearchQuery && searchTerm?.length ? searchTerm : undefined,
        partnerRole: 'SUPPLIER', // For now we only show suppliers
      },
      orderBy: {
        orderByField,
        orderByMethod,
      },
      ...(useInfiniteScroll && { first: 15 }),
    },
    skip,
  });

  const hasNextPage: boolean = !!data?.partners?.pageInfo?.hasNextPage;
  const endCursor = data?.partners?.pageInfo?.endCursor;

  const partners: IBasePartnerCompany[] = useMemo(() => {
    if (!data) return [];
    return data?.partners.edges.map(edge => edge.node);
  }, [data]);

  const filteredPartners = useMemo(() => {
    const searchToUse = customSearch !== undefined ? customSearch : searchTerm;
    if (useSearchQuery || !searchToUse?.length) return partners;

    return partners?.filter(partner => {
      // Filter a list of fields to see which fields have a match with the searchTerm
      return ['title', 'location.name'].some(field =>
        // See if the field includes the search term
        get(partner, field)?.toLowerCase().includes(searchToUse.toLowerCase())
      );
    });
  }, [partners, searchTerm, customSearch, useSearchQuery]);

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const handleCreatePartner = () => {
    logEvent('CLICK_PARTNER_CREATE');

    if (canInvitePartners) {
      openDialog({
        type: 'PARTNER_SELECT_DECISION',
      });
    } else {
      openDialog({
        type: 'ADD_EDIT_PARTNER',
      });
    }
  };

  const clearDeletingPartner = () => {
    setPartnerToDelete(undefined);
  };

  const onDeletePartner = (partner: IBasePartnerCompany) => {
    setPartnerToDelete(partner);
  };

  const onEditPartner = (partner: IBasePartnerCompany) => {
    openDialog({ type: 'ADD_EDIT_PARTNER', props: { partner } });
  };

  const handleSearchTermChange = (term: string) => {
    setQuery({ [appQueryParams.query]: term });
    logEvent('PARTNER_SEARCHED', { searchTerm: term });
  };

  useEffect(() => {
    if (addNewPartner) {
      handleCreatePartner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewPartner]);

  return {
    partners: filteredPartners,
    searchTerm,
    hasNextPage,
    loading,
    error,
    partnerToDelete,
    handleCreatePartner,
    handleSearchTermChange,
    clearDeletingPartner,
    onDeletePartner,
    onEditPartner,
    handlePageEndReached,
    refetch,
  };
};

export default usePartners;
