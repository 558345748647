import { DatasetColumn, DatasetColumnType } from 'types/dataset.types';

export const DatasetColumnKeys: DatasetColumnType[] = Object.values(DatasetColumn);

export const DatasetColumnName: Record<DatasetColumnType, string> = {
  [DatasetColumn.FARM_ID]: 'Farm ID',
  [DatasetColumn.FARM_OWNER]: 'Farm owner name',
  [DatasetColumn.FARM_NAME]: 'Farm name',
  [DatasetColumn.COORDINATES_LAT_LNG]: 'Coordinates (Lat/Lng)',
  [DatasetColumn.COORDINATES_LNG_LAT]: 'Coordinates (Lng/Lat)',
  [DatasetColumn.LATITUDE]: 'Latitude',
  [DatasetColumn.LONGITUDE]: 'Longitude',
  [DatasetColumn.COORDINATES_DD]: 'Coordinates DD (40.7128° N, 74.0060° W)',
  [DatasetColumn.COORDINATES_DMS]: "Coordinates DDM (40°42.767' N, 74°0.35' W)",
  [DatasetColumn.COORDINATES_DDM]: 'Coordinates DDM (40°42\'46" N, 74°0\'21" W',
  [DatasetColumn.LATITUDE_DD]: 'Coordinates DD North (40.7128° N)',
  [DatasetColumn.LONGITUDE_DD]: 'Coordinates DD West (74.0060° W)',
  [DatasetColumn.LATITUDE_DMS]: 'Coordinates DMS North (40°42\'46" N)',
  [DatasetColumn.LONGITUDE_DMS]: 'Coordinates DMS West (74°0\'21" W)',
  [DatasetColumn.LATITUDE_DDM]: "Coordinates DDM North (40°42.767' N)",
  [DatasetColumn.LONGITUDE_DDM]: "Coordinates DDM West (74°0.35' W)",
  [DatasetColumn.FARM_PLOT_SIZE_POINT]: 'Farm plot size (Hectares) (Point decimal 1.0)',
  [DatasetColumn.FARM_PLOT_SIZE_COMMA]: 'Farm plot size (Hectares) (Comma decimal 1,0)',
};
