import { Box } from '@mui/material';
import { PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { InfoTooltip, Tabs } from 'designSystem';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes } from 'types/enums';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { CustomerPlan } from 'types/company.types';

enum ComponentChainTabKey {
  COMPONENTS = 'components',
  CHAINS = 'component-chains',
}

interface IComponentsLayoutProps extends PropsWithChildren {
  selectedTab: `${ComponentChainTabKey}`;
}

const COMPONENT_TABS: ITab<ComponentChainTabKey>[] = [
  {
    key: ComponentChainTabKey.COMPONENTS,
    label: 'Components',
    startIconName: 'components',
  },
  {
    key: ComponentChainTabKey.CHAINS,
    label: 'Component chains',
    startIconName: 'component-chain',
  },
];

const ComponentsLayout: FC<IComponentsLayoutProps> = ({ children, selectedTab }) => {
  usePageTitle('Components');
  const { plan } = useCompanyPlanContext();
  const isPartner = plan === CustomerPlan.FREEMIUM;

  const navigate = useNavigate();

  const handleChangeTab = (tab: ComponentChainTabKey) => {
    if (tab === ComponentChainTabKey.COMPONENTS) {
      navigate(`/components`);
    } else {
      navigate(`/component-chains`);
    }
  };

  return (
    <PageContainer>
      <Box display="flex" alignItems="center">
        <PageTitle
          title="Components"
          TitleAdornment={
            <Box ml={1}>
              <InfoTooltip
                size={AvailableSizes.LARGE}
                text={`Components are ingredients, packaging or something else that makes up your end products. All components that are part of your chains appear here. 
                  You can specify what components are coming from what partner and re-use your components in several chain activities. Component chains are re-usable sub-supply chains. 
                  They describe the material flow of your components${
                    isPartner
                      ? '.'
                      : ', and can also be re-used across several of your different product’s supply chains.'
                  }`}
              />
            </Box>
          }
        />
      </Box>

      <Box mt={3} mb={1}>
        <Tabs
          tabs={COMPONENT_TABS}
          selectedTab={selectedTab as ComponentChainTabKey}
          onChange={handleChangeTab}
        />
      </Box>

      {children}
    </PageContainer>
  );
};

export default ComponentsLayout;
