import { gql } from '@apollo/client';
import { PRODUCT_FRAGMENT } from 'graphql/fragments';
import { PARTNER_REQUEST_FRAGMENT } from 'graphql/fragments/partner.fragments';
import { PRODUCT_WITH_BASE_CHAIN_FRAGMENT } from 'graphql/fragments/product.fragments';

export const GET_PRODUCTS = gql`
  query GetProducts($filters: ProductsFilters) {
    products(filters: $filters) {
      edges {
        node {
          ...productValues
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    products {
      edges {
        node {
          ...productValues
        }
      }
    }
    archivedProducts: products(filters: { status: ARCHIVED }) {
      edges {
        node {
          ...productValues
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS_HOME = gql`
  query GetProductsHome($filters: ProductsFilters, $requestFilters: PartnerRequestsFilters) {
    products(filters: $filters) {
      edges {
        node {
          ...productValues
        }
      }
    }
    companyAnalyticsExist
    overallProductStatus @client {
      status
      homeTitle
    }
    partnerRequests(filters: $requestFilters) {
      count
      edges {
        node {
          ...partnerRequestValues
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const GET_PRODUCT = gql`
  query GetProduct($id: UUID!) {
    product(id: $id) {
      ...productValues
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_AND_BASE_CHAIN = gql`
  query GetProductAndBaseChain($id: UUID!) {
    product(id: $id) {
      ...productWithBaseChain
    }
  }
  ${PRODUCT_WITH_BASE_CHAIN_FRAGMENT}
`;

export const GET_PRODUCTS_AND_BASE_CHAIN = gql`
  query GetProductsAndBaseChain($filters: ProductsFilters) {
    products(filters: $filters) {
      edges {
        node {
          ...productWithBaseChain
        }
      }
    }
  }
  ${PRODUCT_WITH_BASE_CHAIN_FRAGMENT}
`;

export const GET_PRODUCT_AND_ANALYTICS_EXIST = gql`
  query GetProductAndAnalyticsExist($id: UUID!) {
    product(id: $id) {
      ...productValues
    }
    productAnalyticsExist(id: $id)
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_STATUS = gql`
  query GetProductStatus($productId: UUID!) {
    product(productId: $productId) {
      id
      status
      progress
    }
  }
`;

export const GET_SLUG_AVAILABILITY = gql`
  query GetSlugAvailability($slug: String!) {
    getSlugAvailability(slug: $slug) {
      data {
        slug
        available
      }
    }
  }
`;
