import React, { FC, useMemo } from 'react';
import { IDataset } from 'types/dataset.types';
import PlotWarningBanner from '../PlotWarningBanner';
import { Box, styled } from '@mui/material';
import InfiniteScrollWrapper from 'components/Structure/InfiniteScrollWrapper';
import { ISite, SiteType } from 'types/site.types';
import SiteItem from '../SiteItem';
import { useDialog } from 'components/hooks';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';
import { GET_DATASET_SITES } from 'graphql/queries/dataset.queries';
import { useQuery } from '@apollo/client';
import { GraphQlConnection } from 'types/types';
import { removeGraphConnections } from 'utils/graphql.utils';
import { ErrorState } from 'components/Structure';
import { Loader } from 'components/Forms';

interface IDatasetVerificationProps {
  dataset: IDataset;
}

const Container = styled(Box)({
  width: '100%',
  paddingBottom: 80,
});

const DatasetVerification: FC<IDatasetVerificationProps> = ({ dataset }) => {
  const { data, loading, error, fetchMore } = useQuery<{
    dataset: { sites: GraphQlConnection<ISite> };
  }>(GET_DATASET_SITES, {
    variables: { datasetId: dataset.id, first: 15 },
  });
  const { openDialog } = useDialog();
  const { deleteSite } = useSiteMutations();

  const sites: ISite[] = useMemo(
    () => (data?.dataset.sites ? removeGraphConnections(data.dataset.sites) : []),
    [data]
  );

  const hasNextPage: boolean = !!data?.dataset.sites?.pageInfo?.hasNextPage;

  const endCursor: string | undefined = data?.dataset.sites?.pageInfo?.endCursor;

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const warningTypes = useMemo(() => {
    const issueCounts: { errorMessage: string; count: number }[] = [];
    dataset.issues?.forEach(error => {
      const existingIssues = issueCounts.find(e => e.errorMessage === error.errorMessage);
      if (existingIssues) {
        existingIssues.count += 1;
      } else {
        issueCounts.push({ errorMessage: error.errorMessage, count: 1 });
      }
    });
    return issueCounts;
  }, [dataset.issues]);

  const handleEditSite = (site: ISite) => {
    if (!site) {
      console.warn('No site to edit');
      return;
    }

    openDialog({
      type: 'ADD_EDIT_SITE',
      props: { site, partner: dataset.ownedBy },
    });
  };

  const handleEditCultivationArea = (site: ISite) => {
    if (!site) {
      console.warn('No site to edit cultivation area');
      return;
    }

    openDialog({
      type: 'ADD_EDIT_CULTIVATION_AREA',
      props: {
        site,
      },
    });
  };

  const handleDeleteSite = (site: ISite) => {
    if (!site) {
      console.warn('No site to delete');
      return;
    }

    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete site',
        text: 'Are you sure you want to delete this site? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        itemTitle: site.title,
        displayCloseButton: true,
        onSubmit: () => {
          deleteSite({
            variables: { id: site.id },
            update: (cache, { data }) => {
              if (!data) return;

              const existingSites = cache.readQuery<{
                dataset: { sites: GraphQlConnection<ISite> };
              }>({
                query: GET_DATASET_SITES,
                variables: {
                  datasetId: dataset.id,
                },
              });

              const newSites = existingSites?.dataset.sites.edges.filter(
                ({ node }) => node.id !== data?.deleteSite.id
              );

              cache.writeQuery({
                query: GET_DATASET_SITES,
                data: {
                  dataset: {
                    ...existingSites?.dataset,
                    sites: {
                      ...existingSites?.dataset.sites,
                      edges: newSites,
                    },
                  },
                },
                variables: {
                  datasetId: dataset.id,
                },
              });
            },
          });
        },
        onCancel: () => undefined,
      },
    });
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <>
      {dataset.issues.length > 0 && (
        <>
          <Box display="flex" flexDirection="column" gap={1}>
            <PlotWarningBanner plotWarnings={warningTypes} warningCount={dataset.issues.length} />
          </Box>
          <Box mt={2} />
        </>
      )}

      {sites.length > 0 && !loading && (
        <Container>
          {sites.length > 0 && !loading && (
            <InfiniteScrollWrapper hasMore={hasNextPage} next={handlePageEndReached}>
              {sites
                .filter(site => site.siteType !== SiteType.CLUSTER)
                .map(site => {
                  const warningsOfSite = dataset.issues.filter(issue => issue.entityId === site.id);
                  return (
                    <SiteItem
                      key={site.id}
                      site={site}
                      warnings={warningsOfSite}
                      onEdit={handleEditSite}
                      onEditCultivationArea={handleEditCultivationArea}
                      onDelete={handleDeleteSite}
                    />
                  );
                })}
            </InfiniteScrollWrapper>
          )}
        </Container>
      )}
    </>
  );
};

export default DatasetVerification;
