import { DialogActions, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaField, TextFieldLimited } from 'components/Forms';
import { mergeDefaults } from 'components/Forms/utils';
import { componentChainSchema } from 'constants/schemas/chains.schema';
import { FieldWrapper, ThemeButton } from 'designSystem';
import { FastField as Field, Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { FC } from 'react';
import { IBaseChain } from 'types/chain.types';
import { IBasePartnerCompany } from 'types/partner.types';
import { Cropping } from 'types/media.types';
import PartnerAutocomplete from './PartnerAutocomplete';

const StyledCard = styled(Grid)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  borderRadius: 6,
  minHeight: 150,
  width: '100%',
  padding: theme.spacing(2),
}));

const StyledTextFieldLimited = styled(TextFieldLimited)(() => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-input': {
      fontSize: 12,
      maxHeight: 34,
      padding: '9.88px 12px',
    },
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0),
  justifyContent: 'space-between',
}));

interface IEditComponentChainFormProps {
  chain: IBaseChain;
  onSubmit: (values: {
    title: string;
    image: { imageId: string; crop?: Cropping };
    ownedById?: string;
  }) => void;
  onCancel?: () => void;
}

const EditComponentChainForm: FC<IEditComponentChainFormProps> = ({
  chain,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = (values: {
    title: string;
    image: {
      imageId: string;
      crop?: Cropping;
    };
    ownedBy?: IBasePartnerCompany;
  }) => {
    onSubmit({
      title: values.title,
      image: values.image,
      ownedById: values.ownedBy?.id,
    });
  };

  const defaultValues = {
    title: chain.title,
    image: chain.image,
    ownedBy: chain.ownedBy,
  };

  const initialValues = mergeDefaults(componentChainSchema.default(), defaultValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={componentChainSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form data-cy="edit-component-chain-form">
          <StyledCard container>
            <Grid item xs={3}>
              <FieldWrapper>
                <Field
                  component={MediaField}
                  name="image"
                  data-cy="field-component-chain-image"
                  legacyImage={false}
                  styles={{
                    width: 116,
                    height: 116,
                    maxWidth: '100%',
                  }}
                />
              </FieldWrapper>
            </Grid>
            <Grid container xs={9}>
              <Grid item xs={12}>
                <FieldWrapper fullWidth>
                  <Field
                    name="title"
                    fullWidth
                    placeholder="E.g. Coffee, Chocolate"
                    component={StyledTextFieldLimited}
                    variant="outlined"
                    charLimit={50}
                    inputProps={{
                      'data-cy': 'field-component-title',
                    }}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12}>
                <FieldWrapper fullWidth>
                  <PartnerAutocomplete
                    name="ownedBy"
                    label="Owner"
                    helperText="Select the actor that is last in this chain and responsible for creating the output component of the whole chain. This company is the owner of  the component chain, even if there are other partners involved."
                  />
                </FieldWrapper>
              </Grid>
            </Grid>
          </StyledCard>
          <StyledDialogActions>
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              className="button-wide"
              data-cy="cancel-component-chain-button"
              onClick={onCancel}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              loading={isSubmitting}
              disabled={!isValid || !!isEqual(initialValues, values)}
              color="YELLOW"
              size="large"
              className="button-wide"
              type="submit"
              data-cy="edit-component-chain-button"
            >
              Save
            </ThemeButton>
          </StyledDialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default EditComponentChainForm;
