import { useQuery } from '@apollo/client';
import { Autocomplete, TextField, styled } from '@mui/material';
import { GET_CHAINS } from 'graphql/queries';
import React, { ChangeEvent, FC, useMemo } from 'react';
import { IBaseChain, IChainsResponse } from 'types/chain.types';
import { removeGraphConnections } from 'utils/graphql.utils';

interface ISupplyChainAutocompleteProps {
  /* Removes the current chain from the selection list */
  filterChainId: string;
  onSupplyChainSelect: (componentChainId?: string) => void;
}

const StyledAutocomplete = styled(Autocomplete<IBaseChain>)({
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
    {
      padding: 4,
      '& .MuiAutocomplete-input': {
        fontSize: 12,
      },
    },
});

const SupplyChainAutocomplete: FC<ISupplyChainAutocompleteProps> = ({
  filterChainId,
  onSupplyChainSelect,
}) => {
  const { data, loading } = useQuery<IChainsResponse>(GET_CHAINS, {
    variables: { filters: { chainType: 'SUPPLY' } },
  });

  const visibleSupplyChains = useMemo(
    () =>
      data?.chains
        ? removeGraphConnections(data.chains).filter(
            ({ id, mappingInitialised }) => id !== filterChainId && mappingInitialised
          )
        : [],
    [data?.chains, filterChainId]
  );

  const handleSelectPartner = (event: ChangeEvent<unknown>, supplyChain: IBaseChain | null) => {
    onSupplyChainSelect(supplyChain?.id);
  };

  return (
    <StyledAutocomplete
      loading={loading}
      options={visibleSupplyChains}
      size="small"
      data-cy="supply-chain-autocomplete"
      onChange={handleSelectPartner}
      getOptionLabel={option => option.title}
      renderOption={(props, option) => <span {...props}>{option.title}</span>}
      renderInput={params => (
        <TextField {...params} variant="outlined" placeholder="Select supply chain" />
      )}
    />
  );
};

export default SupplyChainAutocomplete;
